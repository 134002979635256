import config from "../config";
import HTTPError from "../errors";
import { getMeshBuffers } from "./graphics";
import { getSignalsDifference } from "./signals";

function fetchJson(url) {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new HTTPError(response.status, response.statusText);
    }
    return response.json();
  });
}

export function fetchProbes() {
  const { version: apiVersion, modelId } = config.api;

  return fetchJson(`/api/${apiVersion}/models/${modelId}/probes/`).then((probes) => {
    probes.map((item) => {
      if (item.point_probes) {
        item.pointProbes = probes.filter((probe) => item.point_probes.includes(probe.probe_id));
      }
      if (item.probes_ids) {
        item.multiProbes = probes.filter((probe) => item.probes_ids.includes(probe.probe_id));
      }
      return item;
    });

    return probes;
  });
}

export function fetchPhysicsTypes() {
  const { version: apiVersion } = config.api;
  return fetchJson(`/api/${apiVersion}/physics-types/`);
}

export function fetchMaterialModelTypes() {
  const { version: apiVersion } = config.api;
  return fetchJson(`/api/${apiVersion}/material-models/`);
}

export function fetchMaterials() {
  const { version: apiVersion, scenarioId } = config.api;
  return fetchJson(`/api/${apiVersion}/scenarios/${scenarioId}/materials/`);
}

export function fetchProbeSignals(probeId, dateRange) {
  const { version: apiVersion, scenarioId } = config.api;
  let queryParams = getQueryParamsForSignalFetch(dateRange);

  let url = `/api/${apiVersion}/scenarios/${scenarioId}/probes-signals/${probeId}`;
  if (queryParams) {
    url += "?" + new URLSearchParams(queryParams);
  }

  return fetchJson(url).then((probeSignals) => processProbeSignals(probeSignals));
}

export function fetchOriginalMeasurementSignal(probeId, dateRange) {
  const { version: apiVersion, scenarioId } = config.api;
  const queryParams = getQueryParamsForSignalFetch(dateRange);
  let url = `/api/${apiVersion}/scenarios/${scenarioId}/probes-original-measurements/${probeId}`;
  if (queryParams) {
    url += "?" + new URLSearchParams(queryParams);
  }

  return fetchJson(url).then((probeSignals) =>
    probeSignals?.find((signal) => signal.signal_type === "original_measurement")
  );
}

export function fetchFilteredMeasurementSignal(probeId, dateRange) {
  const { version: apiVersion, scenarioId } = config.api;
  const queryParams = getQueryParamsForSignalFetch(dateRange);
  let url = `/api/${apiVersion}/scenarios/${scenarioId}/probes-filtered-measurements/${probeId}`;
  if (queryParams) {
    url += "?" + new URLSearchParams(queryParams);
  }

  return fetchJson(url).then((probeSignals) =>
    probeSignals?.find((signal) => signal.signal_type === "filtered_measurement")
  );
}

export function fetchSimulationSignal(probeId, dateRange) {
  const { version: apiVersion, scenarioId } = config.api;
  const queryParams = getQueryParamsForSignalFetch(dateRange);
  let url = `/api/${apiVersion}/scenarios/${scenarioId}/probes-simulations/${probeId}`;
  if (queryParams) {
    url += "?" + new URLSearchParams(queryParams);
  }

  return fetchJson(url).then((probeSignals) =>
    probeSignals?.find((signal) => signal.signal_type === "simulation")
  );
}

function processProbeSignals(probeSignals) {
  const probeSignalsMerged = mergeProbeSignals(probeSignals);

  const originalMeasurement = probeSignals.find(
    (signal) => signal.signal_type === "original_measurement"
  );
  const simulationMeasurement = probeSignals.find((signal) => signal.signal_type === "simulation");

  if (probeSignalsMerged && originalMeasurement && simulationMeasurement) {
    probeSignalsMerged.difference = getSignalsDifference(
      simulationMeasurement,
      originalMeasurement
    );
  }

  return probeSignalsMerged;
}

function mergeProbeSignals(probeSignals) {
  if (!probeSignals || probeSignals.length === 0) {
    return null;
  }

  const result = {};
  probeSignals.forEach((probeSignal) => {
    if (result.scenarioId) {
      if (result.scenarioId !== probeSignal.scenario_id) {
        throw new Error("Signals for more scenarios are present.");
      }
    } else if (probeSignal.scenario_id) {
      result.scenarioId = probeSignal.scenario_id;
    }

    if (result.probeId) {
      if (result.probeId !== probeSignal.probe_id) {
        throw new Error("Signals for more probes are present.");
      }
    } else if (probeSignal.probe_id) {
      result.probeId = probeSignal.probe_id;
    }

    result[probeSignal.signal_type] = probeSignal;
  });

  return result;
}

function getQueryParamsForSignalFetch(dateRange) {
  const dateFormat = "YYYY-MM-DDTHH:mm:ss";
  const startTime = dateRange?.start?.format(dateFormat);
  const endTime = dateRange?.end?.format(dateFormat);

  let queryParams;
  if (startTime || endTime) {
    queryParams = {};
    if (startTime) queryParams.startTime = startTime;
    if (endTime) queryParams.endTime = endTime;
  }

  return queryParams;
}

export function fetchProbePlotPreferences(probeId) {
  const { version: apiVersion } = config.api;
  return fetchJson(`/api/${apiVersion}/probes/${probeId}/plot-preferences/`);
}

export function fetchSolutionHistory(includeOutput) {
  const { version: apiVersion, scenarioId } = config.api;

  return fetchJson(
    `/api/${apiVersion}/scenarios/${scenarioId}/solution-records/?` +
      new URLSearchParams({
        "include-output": includeOutput,
      })
  );
}

export function fetchAnalyses() {
  const { version: apiVersion, modelId } = config.api;
  return fetchJson(`/api/${apiVersion}/models/${modelId}/analyses/`);
}

export function fetchMesh() {
  const { version: apiVersion, scenarioId } = config.api;
  return fetchJson(`/api/${apiVersion}/scenarios/${scenarioId}/mesh/`);
}

export function loadProbes(setProbes, setIsLoading, setError) {
  setIsLoading(true);
  return fetchProbes()
    .then((probesData) => {
      setProbes(probesData);
      setError(null);
    })
    .catch((error) => setError(error))
    .finally(() => setIsLoading(false));
}

export function loadMaterials(setMaterials, setIsLoading, setError) {
  const materialsFetch = fetchMaterials();
  const materialModelTypesFetch = fetchMaterialModelTypes();
  const physicTypesFetch = fetchPhysicsTypes();

  setIsLoading(true);
  return Promise.all([materialsFetch, materialModelTypesFetch, physicTypesFetch])
    .then(([materials, materialModelTypes, physicsTypes]) => {
      materials.map((mat) => {
        mat.material_models.map((matModel) => {
          const matModelType = materialModelTypes.find(
            (elem) => elem.material_model_type_id === matModel.material_model_type_id
          );
          if (matModelType) {
            matModel.name = matModelType.name;

            const physicsType = physicsTypes.find(
              (elem) => elem.physics_type_id === matModelType.physics_type_id
            );
            if (physicsType) matModel.physicName = physicsType.name;
          }

          return matModel;
        });
        return mat;
      });
      setMaterials(materials);
      setError(null);
    })
    .catch((error) => setError(error))
    .finally(() => setIsLoading(false));
}

export function loadProbeSignals(probeId, setProbeSignals, setIsLoading, setError, dateRange) {
  setIsLoading(true);
  return fetchProbeSignals(probeId, dateRange)
    .then((probeSignals) => {
      setProbeSignals(probeSignals);
      setError(null);
    })
    .catch((error) => setError(error))
    .finally(() => setIsLoading(false));
}

export function loadSolutionHistory(setSolutionRecords, setIsLoading, setError) {
  const solutionRecordsFetch = fetchSolutionHistory(false);
  const analysesFetch = fetchAnalyses();

  setIsLoading(true);
  return Promise.all([solutionRecordsFetch, analysesFetch])
    .then(([solutionRecords, analyses]) => {
      const analysisIdToNameMap = new Map();
      analyses.forEach((analysis) => analysisIdToNameMap.set(analysis.analysis_id, analysis.name));

      solutionRecords.forEach((record) => {
        record.endDate = new Date(record.end_time);
        record.analyses_results.forEach((analysisResult) => {
          analysisResult.analysisName = analysisIdToNameMap.get(analysisResult.analysis_id);
        });
      });

      setSolutionRecords(solutionRecords);
      setError(null);
    })
    .catch((error) => setError(error))
    .finally(() => setIsLoading(false));
}

export function fetchAnalysisResultOutput(stepId, analysisId, setOutput, setIsLoading, setError) {
  const { version: apiVersion } = config.api;

  setIsLoading(true);
  return fetchJson(
    `/api/${apiVersion}/steps/${stepId}/solution-record/analyses-results/${analysisId}`
  )
    .then((analysisResult) => {
      setOutput(analysisResult.output);
      setError(null);
    })
    .catch((error) => setError(error))
    .finally(() => setIsLoading(false));
}

export function loadModel(setModel, setIsLoading, setError) {
  const meshFetch = fetchMesh();
  const materialsFetch = fetchMaterials();
  const probesFetch = fetchProbes();

  setIsLoading(true);
  return Promise.all([meshFetch, materialsFetch, probesFetch])
    .then(([mesh, materials, probes]) => {
      const materialsObj = arrayToObject(materials, "material_id");
      const probesObj = arrayToObject(probes, "probe_id");

      const model = {
        mesh,
        materials: materialsObj,
        probes: probesObj,
      };

      const buffers = getMeshBuffers(model.mesh);
      model.mesh.buffers = buffers;

      setModel(model);
      setError(null);
    })
    .catch((error) => setError(error))
    .finally(() => setIsLoading(false));
}

function arrayToObject(array, keyAttribute) {
  return array.reduce((acc, item) => {
    acc[item[keyAttribute]] = item;
    return acc;
  }, {});
}
