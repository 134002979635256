import { useState, useEffect, useMemo, useReducer } from "react";
import { Box, Select, InputLabel, MenuItem, FormControl, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import tinycolor from "tinycolor2";
import Header from "../global/Header";
import DateRangePicker from "../global/DateRangePicker";
import {
  loadProbes,
  fetchOriginalMeasurementSignal,
  fetchSimulationSignal,
  fetchProbePlotPreferences,
} from "../../../utils/data-provider";
import { ErrorAlert, AlertSnackbar, ErrorSnackbar } from "../../global/Info";
import ContentBox from "../global/ContentBox";
import LineChart from "../global/LineChart";
import CircularProgressBox from "../global/CircularProgressBox";
import { getProbeLabel } from "../../../utils/text";

function probesDataReducer(state, action) {
  let result;
  switch (action.type) {
    case "data":
      result = setData(state, action);
      break;
    case "loading":
      result = setLoading(state, action);
      break;
    case "info":
      result = setMessage("info", state, action);
      break;
    case "warning":
      result = setMessage("warning", state, action);
      break;
    case "error":
      result = setMessage("error", state, action);
      break;
    default:
      throw new Error("Unexpected action type.");
  }

  return result;
}

function setData(state, action) {
  let data;
  if (action.data) {
    data = Object.assign(state.data, { [action.probeName]: action.data });
  } else {
    data = { ...state.data };
    delete data[action.probeName];
  }
  return {
    ...state,
    data,
  };
}

function setLoading(state, action) {
  const loading = new Set(state.loading);
  if (action.isLoading) loading.add(action.probeName);
  else loading.delete(action.probeName);

  return {
    ...state,
    loading,
  };
}

function setMessage(level, state, action) {
  const levelPropName = level + "s";

  let messages;
  if (action[level]) {
    messages = Object.assign(state[levelPropName], { [action.probeName]: action[level] });
  } else {
    messages = { ...state[levelPropName] };
    delete messages[action.probeName];
  }

  return {
    ...state,
    [levelPropName]: messages,
  };
}

function useFetchSignals(
  measurementsDispatch,
  simulationsDispatch,
  probes,
  startDate,
  endDate,
  i18n
) {
  return useEffect(() => {
    if (probes) {
      probes.forEach((probe) => {
        const probeName = probe.name;
        const probeLabel = getProbeLabel(probe, i18n);
        function fetchSignal(fetchSignalFunction, signalDispatch, signalNotLoadedStr) {
          signalDispatch({ type: "loading", probeName, isLoading: true });
          return fetchSignalFunction(probe.probe_id, { start: startDate, end: endDate })
            .then((signal) => {
              if (signal) {
                signalDispatch({ type: "data", probeName, data: signal });
              } else {
                signalDispatch({ type: "data", probeName, data: null });
                signalDispatch({
                  type: "info",
                  probeLabel,
                  info: signalNotLoadedStr,
                });
              }
              signalDispatch({ type: "error", probeName, error: null });
            })
            .catch((error) => {
              signalDispatch({ type: "data", probeName, data: null });
              if (error.status === 404) {
                signalDispatch({
                  type: "info",
                  probeLabel,
                  info: signalNotLoadedStr,
                });
              } else {
                signalDispatch({ type: "error", probeName, error });
              }
            })
            .finally(() => signalDispatch({ type: "loading", probeName, isLoading: false }));
        }

        if (measurementsDispatch) {
          const measurementNotLoadedStr = i18n.t("no_original_measurement_signal_loaded", {
            probeName: probeLabel,
          });
          fetchSignal(
            fetchOriginalMeasurementSignal,
            measurementsDispatch,
            measurementNotLoadedStr
          );
        }

        if (simulationsDispatch) {
          const simulationNotLoadedStr = i18n.t("no_simulation_signal_loaded", {
            probeName: probeLabel,
          });
          fetchSignal(fetchSimulationSignal, simulationsDispatch, simulationNotLoadedStr);
        }
      });
    }
  }, [probes, startDate, endDate, measurementsDispatch, simulationsDispatch, i18n]);
}

function useFetchProbePlotPreferences(probePlotPreferences, probePlotPreferencesDispatch, probes) {
  function plotPreferencesNotReceivedHandler(probe) {
    console.warn(`Plot preferences not received for probe (ID: ${probe.probe_id}).`);
  }

  return useEffect(() => {
    const defaultPlotPreferences = {
      simulation_quantity_offset: 0.0,
    };

    const probesToFetch = probePlotPreferences
      ? probes.filter((probe) => probePlotPreferences.data[probe.name] === undefined)
      : probes;

    probesToFetch.forEach((probe) => {
      const probeName = probe.name;
      probePlotPreferencesDispatch({ type: "loading", probeName, isLoading: true });
      return fetchProbePlotPreferences(probe.probe_id)
        .then((plotPreferences) => {
          if (plotPreferences) {
            probePlotPreferencesDispatch({ type: "data", probeName, data: plotPreferences });
          } else {
            plotPreferencesNotReceivedHandler(probe);
            probePlotPreferencesDispatch({ type: "data", probeName, data: defaultPlotPreferences });
          }
          probePlotPreferencesDispatch({ type: "error", probeName, error: null });
        })
        .catch((error) => {
          if (error.status === 404) {
            plotPreferencesNotReceivedHandler(probe);
            probePlotPreferencesDispatch({ type: "data", probeName, data: defaultPlotPreferences });
          } else {
            probePlotPreferencesDispatch({ type: "data", probeName, data: null });
            probePlotPreferencesDispatch({ type: "error", probeName, error });
          }
        })
        .finally(() =>
          probePlotPreferencesDispatch({ type: "loading", probeName, isLoading: false })
        );
    });

    // eslint-disable-next-line
  }, [probes, probePlotPreferencesDispatch]);
}

function useGetQuantity(probes) {
  return useMemo(() => {
    return probes?.reduce((acc, probe) => {
      if (acc === undefined) acc = probe.quantity;
      else if (acc !== probe.quantity) acc = null;
      return acc;
    }, undefined);
  }, [probes]);
}

function useGetUnit(probes) {
  return useMemo(() => {
    return probes?.reduce((acc, probe) => {
      if (acc === undefined) acc = probe.unit;
      else if (acc !== probe.unit) acc = null;
      return acc;
    }, undefined);
  }, [probes]);
}

function useGetGraphData(probes, measurements, simulations, probePlotPreferences, colors, i18n) {
  return useMemo(() => {
    return (probes || []).reduce((acc, probe, index) => {
      const measurementSignal = measurements?.data[probe.name];
      if (measurementSignal) {
        acc.push({
          probeName: probe.name,
          name: `${getProbeLabel(probe, i18n)} - ${i18n.t("measurement")}`,
          x: measurementSignal.times,
          y: measurementSignal.values,
          type: "scattergl",
          mode: "markers",
          marker: {
            color: colors[index % colors.length],
            size: 3,
          },
        });
      }

      const simulationQuantityOffset =
        probePlotPreferences?.data[probe.name]?.simulation_quantity_offset;
      if (simulationQuantityOffset !== undefined) {
        const simulationSignal = simulations?.data[probe.name];
        if (simulationSignal) {
          acc.push({
            probeName: probe.name,
            name: `${getProbeLabel(probe, i18n)} - ${i18n.t("simulation")}`,
            x: simulationSignal.times,
            y: simulationSignal.values.map((value) => value + simulationQuantityOffset),
            type: "scattergl",
            mode: "lines+markers",
            marker: {
              color: tinycolor(colors[index % colors.length])
                .darken()
                .toHexString(),
              size: 3,
            },
          });
        }
      }

      return acc;
    }, []);
  }, [probes, measurements, simulations, probePlotPreferences, colors, i18n]);
}

const Profiles = () => {
  const theme = useTheme();
  const [profileId, setProfileId] = useState("");
  const [startDate, setStartDate] = useState(
    dayjs().subtract(7, "day").hour(0).minute(0).second(0).millisecond(0)
  );
  const [endDate, setEndDate] = useState(dayjs());
  const [probes, setProbes] = useState(null);
  const [loadingProbes, setLoadingProbes] = useState(false);
  const [probeError, setProbeError] = useState(null);
  const { t, i18n } = useTranslation();

  const [measurements, measurementsDispatch] = useReducer(probesDataReducer, {
    data: {},
    loading: new Set(),
    infos: {},
    warnings: {},
    errors: {},
  });
  const [simulations, simulationsDispatch] = useReducer(probesDataReducer, {
    data: {},
    loading: new Set(),
    infos: {},
    warnings: {},
    errors: {},
  });
  const [probePlotPreferences, probePlotPreferencesDispatch] = useReducer(probesDataReducer, {
    data: {},
    loading: new Set(),
    infos: {},
    warnings: {},
    errors: {},
  });

  useEffect(() => {
    loadProbes(setProbes, setLoadingProbes, setProbeError);
  }, []);

  const profileIdsToOriginalMeasurementProbeNames = useMemo(() => {
    return {
      3: ["HLADINA_A", "VN-03-01_A", "VS-03-01_A"],
      5: ["HLADINA_A", "VN-05-01_A", "VS-05-01_A", "VN-05-02_A", "VS-05-02_A"],
      8: ["HLADINA_A", "VN-08-01_A", "VS-08-01_A"],
      10: ["HLADINA_A", "VN-10-02_A", "VS-10-01_A", "VS-10-02_A"],
    };
  }, []);

  const profileIdsToSimultionProbeNames = useMemo(() => {
    return {
      3: ["VN-03-01_A", "VS-03-01_A"],
      5: ["VN-05-01_A", "VS-05-01_A", "VN-05-02_A", "VS-05-02_A"],
      8: ["VN-08-01_A", "VS-08-01_A"],
      10: ["VN-10-02_A", "VS-10-01_A", "VS-10-02_A"],
    };
  }, []);

  const colors = useMemo(() => ["#00B4E2", "#3BE200", "#E28800", "#00FFFF", "#FF00FF"], []);

  const rainFallProbes = useMemo(() => {
    const rainFallProbe = probes?.find((probe) => probe.name === "SRAZKY_A");
    return rainFallProbe ? [rainFallProbe] : [];
  }, [probes]);

  const airTemperatureProbes = useMemo(() => {
    const airTemperatureProbe = probes?.find((probe) => probe.name === "T-VZDUCH_A");
    return airTemperatureProbe ? [airTemperatureProbe] : [];
  }, [probes]);

  const originalMeasurementWaterLevelProbes = useMemo(() => {
    const measurementProfileProbes = new Set(profileIdsToOriginalMeasurementProbeNames[profileId]);
    const measurementsWaterLevelProbes = probes?.filter((probe) =>
      measurementProfileProbes.has(probe.name)
    );
    return measurementsWaterLevelProbes ? measurementsWaterLevelProbes : [];
  }, [probes, profileId, profileIdsToOriginalMeasurementProbeNames]);

  const simulationWaterLevelProbes = useMemo(() => {
    const simulationProfileProbes = new Set(profileIdsToSimultionProbeNames[profileId]);
    const simulationsWaterLevelProbes = probes?.filter((probe) =>
      simulationProfileProbes.has(probe.name)
    );
    return simulationsWaterLevelProbes ? simulationsWaterLevelProbes : [];
  }, [probes, profileId, profileIdsToSimultionProbeNames]);

  const waterLevelProbes = useMemo(() => {
    const result = {};
    originalMeasurementWaterLevelProbes.forEach((probe) => (result[probe.probe_id] = probe));
    simulationWaterLevelProbes.forEach((probe) => (result[probe.probe_id] = probe));
    return Object.values(result);
  }, [originalMeasurementWaterLevelProbes, simulationWaterLevelProbes]);

  useFetchSignals(measurementsDispatch, null, rainFallProbes, startDate, endDate, i18n);
  useFetchSignals(measurementsDispatch, null, airTemperatureProbes, startDate, endDate, i18n);

  useFetchProbePlotPreferences(
    probePlotPreferences,
    probePlotPreferencesDispatch,
    simulationWaterLevelProbes
  );
  useFetchSignals(
    measurementsDispatch,
    null,
    originalMeasurementWaterLevelProbes,
    startDate,
    endDate,
    i18n
  );
  useFetchSignals(null, simulationsDispatch, simulationWaterLevelProbes, startDate, endDate, i18n);

  const rainFallProbesQuantity = useGetQuantity(rainFallProbes);
  const rainFallProbesUnit = useGetUnit(rainFallProbes);
  const rainFallChartYLabel = getYLabel(rainFallProbesQuantity, rainFallProbesUnit, t);

  const airTemperatureProbesQuantity = useGetQuantity(airTemperatureProbes);
  const airTemperatureProbesUnit = useGetUnit(airTemperatureProbes);
  const airTemperatureChartYLabel = getYLabel(
    airTemperatureProbesQuantity,
    airTemperatureProbesUnit,
    t
  );

  const waterLevelProbesQuantity = useGetQuantity(waterLevelProbes);
  const waterLevelProbesUnit = useGetUnit(waterLevelProbes);
  const waterLevelChartYLabel = getYLabel(waterLevelProbesQuantity, waterLevelProbesUnit, t);

  const rainFallProbesData = useGetGraphData(
    rainFallProbes,
    measurements,
    null,
    null,
    colors,
    i18n
  );
  const airTemperatureProbesData = useGetGraphData(
    airTemperatureProbes,
    measurements,
    null,
    null,
    colors,
    i18n
  );
  const waterLevelProbesData = useGetGraphData(
    waterLevelProbes,
    measurements,
    simulations,
    probePlotPreferences,
    colors,
    i18n
  );

  rainFallProbesData.forEach((signal) => {
    signal.legendgroup = "rainFall";
    signal.xaxis = "x";
    signal.yaxis = "y";
  });

  airTemperatureProbesData.forEach((signal) => {
    signal.legendgroup = "airTemperature";
    signal.xaxis = "x";
    signal.yaxis = "y2";
  });

  waterLevelProbesData.forEach((signal) => {
    signal.legendgroup = "waterLevel";
    signal.xaxis = "x";
    signal.yaxis = "y3";
  });

  const loadingSignal = [measurements, simulations].some((signalType) => {
    return Boolean(probes?.some((probe) => signalType.loading.has(probe.name)));
  });
  const loadingProbePlotPreferences = Boolean(
    probes?.some((probe) => probePlotPreferences.loading.has(probe.name))
  );
  const isLoading = loadingProbes || loadingSignal || loadingProbePlotPreferences;

  const graphData = [...rainFallProbesData, ...airTemperatureProbesData, ...waterLevelProbesData];
  const profileIds = useMemo(
    () => [
      ...new Set([
        ...Object.keys(profileIdsToOriginalMeasurementProbeNames),
        ...Object.keys(profileIdsToSimultionProbeNames),
      ]),
    ],
    [profileIdsToOriginalMeasurementProbeNames, profileIdsToSimultionProbeNames]
  );

  return (
    <>
      <Box>
        <Header title={t("profiles")} />
        {probeError ? (
          <ErrorAlert error={probeError} />
        ) : (
          <ContentBox
            sx={{
              width: "100%",
              padding: "30px",
              mt: "20px",
              minWidth: "500px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                pb: "10px",
              }}
            >
              <FormControl fullWidth sx={{ maxWidth: "200px", mr: "10px" }}>
                <InputLabel id="profile-select-label">{t("profile")}</InputLabel>
                <Select
                  labelId="profile-select-label"
                  id="profile-select"
                  value={profileId}
                  label="Profile"
                  onChange={(event) => setProfileId(event.target.value)}
                >
                  {profileIds.map((profileId) => {
                    return (
                      <MenuItem key={profileId} value={profileId}>
                        {t("profile")} {profileId}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <DateRangePicker
                startDate={startDate}
                endDate={endDate}
                startDateOnChange={(newDate) => setStartDate(newDate)}
                endDateOnChange={(newDate) => setEndDate(newDate)}
              />
            </Box>
            <Box sx={{ position: "relative" }}>
              {isLoading && (
                <CircularProgressBox
                  backgroundColor={theme.palette.background.dark}
                  sx={{ position: "absolute", zIndex: 100 }}
                />
              )}
              <LineChart
                height={1000}
                margin={{
                  t: 30,
                  b: 60,
                }}
                data={graphData}
                xaxis={{ title: { text: t("time") }, autorange: true, type: "date" }}
                yaxis={{
                  title: { text: rainFallChartYLabel, standoff: 20 },
                  domain: [0.76, 1],
                  autorange: false,
                  range: [0, 100],
                }}
                yaxis2={{
                  title: { text: airTemperatureChartYLabel, standoff: 20 },
                  domain: [0.51, 0.74],
                  autorange: false,
                  range: [-30, 40],
                }}
                yaxis3={{
                  title: { text: waterLevelChartYLabel, standoff: 20 },
                  domain: [0, 0.49],
                  autorange: false,
                  range: [355, 385],
                }}
                grid={{
                  rows: 3,
                  columns: 1,
                  subplots: [["xy"], ["xy2"], ["xy3"]],
                }}
                legend={{
                  tracegroupgap: 210,
                }}
              />
            </Box>
          </ContentBox>
        )}
      </Box>
      {["info", "warning"].map((messageLevel) => {
        return [
          { data: measurements, dispatch: measurementsDispatch },
          { data: simulations, dispatch: simulationsDispatch },
          { data: probePlotPreferences, dispatch: probePlotPreferencesDispatch },
        ].map((dataSource) => {
          return Object.entries(dataSource.data[messageLevel + "s"]).map(([probeName, message]) => (
            <AlertSnackbar
              key={`${probeName} - ${messageLevel}`}
              open={true}
              msg={message}
              closeFunc={() =>
                dataSource.dispatch({ type: messageLevel, probeName, [messageLevel]: null })
              }
              severity={messageLevel}
            />
          ));
        });
      })}
      {[
        { data: measurements, dispatch: measurementsDispatch },
        { data: simulations, dispatch: simulationsDispatch },
        { data: probePlotPreferences, dispatch: probePlotPreferencesDispatch },
      ].map((dataSource) => {
        return Object.entries(dataSource.data.errors).map(([probeName, error]) => (
          <ErrorSnackbar
            key={probeName}
            error={error}
            unsetError={() => dataSource.dispatch({ type: "error", probeName, error: null })}
          />
        ));
      })}
    </>
  );
};

function getYLabel(quantity, unit, t) {
  return `${quantity ? t(quantity) : "-"} [${unit ? t(unit) : "-"}]`;
}

export default Profiles;
