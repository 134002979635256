import { useState, useMemo, createContext, useContext, useEffect, useCallback } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/cs";
import "dayjs/locale/en";
import "dayjs/locale/de";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  enUS as enUS_DataGrid,
  csCZ as csCZ_DataGrid,
  deDE as deDE_DataGrid,
} from "@mui/x-data-grid";
import {
  enUS as enUS_DatePickers,
  csCZ as csCZ_DatePickers,
  deDE as deDE_DatePickers,
} from "@mui/x-date-pickers/locales";
import i18n from "i18next";

export const getColors = (mode) => ({
  ...(mode === "light"
    ? {
        // palette values for light mode
        grey: {
          100: "#eeeeee",
          200: "#d7d7d7",
          300: "#c1c1c1",
          400: "#ababab",
          500: "#959595",
          600: "#7f7f7f",
          700: "#696969",
          800: "#535353",
          900: "#3d3d3d",
        },
        primary: {
          100: "#ccf0f9",
          200: "#99e1f3",
          300: "#66d2ee",
          400: "#33c3e8",
          500: "#00b4e2",
          600: "#0090b5",
          700: "#006c88",
          800: "#00485a",
          900: "#00242d",
        },
      }
    : {
        // palette values for dark mode
        grey: {
          100: "#3d3d3d",
          200: "#535353",
          300: "#696969",
          400: "#7f7f7f",
          500: "#959595",
          600: "#ababab",
          700: "#c1c1c1",
          800: "#d7d7d7",
          900: "#eeeeee",
        },
        primary: {
          100: "#00242d",
          200: "#00485a",
          300: "#006c88",
          400: "#0090b5",
          500: "#00b4e2",
          600: "#33c3e8",
          700: "#66d2ee",
          800: "#99e1f3",
          900: "#ccf0f9",
        },
      }),
});

export const getThemeSettings = (mode) => {
  const colors = getColors(mode);

  return {
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: {
              main: "#0074b7",
            },
            background: {
              default: colors.grey[100],
              main: "#FFFFFF",
              dark: colors.grey[100],
            },
          }
        : {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            background: {
              default: colors.grey[100],
              main: colors.grey[200],
              dark: colors.grey[100],
            },
          }),
    },
    typography: {
      fontFamily: ["Roboto", "sans-serif"].join(","),
      fontSize: 14,
      graph: {
        fontSize: 12,
      },
      h1: {
        fontSize: 46,
      },
      h2: {
        fontSize: 40,
      },
      h3: {
        fontSize: 36,
      },
      h4: {
        fontSize: 32,
      },
      h5: {
        fontSize: 24,
      },
      h6: {
        fontSize: 20,
      },
    },
  };
};

export const getTableStyle = (theme) => {
  return {
    "& .MuiDataGrid-root": {
      border: "0px",
    },
    "& .MuiDataGrid-main": {
      backgroundColor: theme.palette.background.main,
    },
    "&& .MuiDataGrid-cell": {
      borderBottom: `1px solid ${theme.palette.background.dark}`,
    },
    "&& .MuiDataGrid-columnHeaders": {
      borderBottom: `1px solid ${theme.palette.background.dark}`,
    },
    "&& .MuiDataGrid-iconSeparator": {
      color: theme.palette.background.dark,
    },
    "&& .MuiDataGrid-footerContainer": {
      backgroundColor: theme.palette.background.main,
      borderTop: `1px solid ${theme.palette.background.dark}`,
    },
    "&& .MuiButtonBase-root.MuiButton-root": {
      color: theme.palette.text.secondary,
    },
  };
};

const ThemeModeUpdateContext = createContext();

export function useThemeModeUpdate() {
  return useContext(ThemeModeUpdateContext);
}

const getMuiLocalizationObjects = (lng) => {
  switch (lng) {
    case "en":
      return {
        dataGrid: enUS_DataGrid,
        datePickers: enUS_DatePickers,
      };
    case "cs":
      return {
        dataGrid: csCZ_DataGrid,
        datePickers: csCZ_DatePickers,
      };
    case "de":
      return {
        dataGrid: deDE_DataGrid,
        datePickers: deDE_DatePickers,
      };
    default:
      return undefined;
  }
};

export function ThemeProvider({ children, forceMode }) {
  const [mode, setMode] = useState("light");
  const [muiLocalizationObjects, setMuiLocalizationObjects] = useState(
    getMuiLocalizationObjects(i18n.language)
  );

  const handleLanguageChanged = useCallback((lng) => {
    setMuiLocalizationObjects(getMuiLocalizationObjects(lng));
  }, []);

  useEffect(() => {
    i18n.on("languageChanged", handleLanguageChanged);
    return () => {
      i18n.off("languageChanged", handleLanguageChanged);
    };
  }, [handleLanguageChanged]);

  const theme = useMemo(() => {
    const muiLocalizations = muiLocalizationObjects ? Object.values(muiLocalizationObjects) : [];
    return createTheme(getThemeSettings(forceMode ? forceMode : mode), ...muiLocalizations);
  }, [mode, forceMode, muiLocalizationObjects]);

  const toggleMode = useCallback(() => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
      <ThemeModeUpdateContext.Provider value={toggleMode}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </ThemeModeUpdateContext.Provider>
    </LocalizationProvider>
  );
}
