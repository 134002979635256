import { useState, useEffect, useRef } from "react";
import {
  Box,
  Slider,
  Checkbox,
  Typography,
  FormGroup,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import { Canvas } from "@react-three/fiber";
import { CameraControls, PerspectiveCamera, Html } from "@react-three/drei";
import { useTranslation } from "react-i18next";
import { ErrorAlert } from "../../global/Info";
import { loadModel } from "../../../utils/data-provider";
import { hexToRgb, rgbToHex } from "../../../utils/graphics";
import CircularProgressBox from "../global/CircularProgressBox";
import ContentBox from "../global/ContentBox";
import { getProbeLabel } from "../../../utils/text";

const SettingsPanel = ({
  opacity,
  opacityOnChange,
  displayMaterials,
  displayMaterialsOnChange,
  displayProbes,
  displayProbesOnChange,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: "absolute",
        width: "260px",
        zIndex: 30000000,
        backgroundColor: theme.palette.background.dark,
        opacity: 0.7,
        m: "5px",
        pr: "15px",
        pl: "15px",
        pt: "15px",
        pb: "5px",
      }}
    >
      <Box>
        <Typography>{t("model_opacity", { opacity })}</Typography>
        <Slider value={opacity} onChange={opacityOnChange} />
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={displayMaterials} onChange={displayMaterialsOnChange} />}
            label={t("display_materials")}
          />
          <FormControlLabel
            control={<Checkbox checked={displayProbes} onChange={displayProbesOnChange} />}
            label={t("display_point_probes")}
          />
        </FormGroup>
      </Box>
    </Box>
  );
};

const InfoBox = ({ position, children }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: position.y + 10,
        left: position.x + 10,
        zIndex: 20000000,
        borderColor: theme.palette.background.dark,
        borderWidth: 1,
        borderStyle: "solid",
        borderRadius: "10px",
        backgroundColor: theme.palette.background.main,
        p: "10px",
      }}
    >
      {children}
    </Box>
  );
};

const Viewer = ({ sx }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [model, setModel] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [opacity, setOpacity] = useState(100);
  const [hoveredMeshIndex, setHoveredMeshIndex] = useState(null);
  const [hoveredMaterialId, setHoveredMaterialId] = useState(null);
  const [displayMaterials, setDisplayMaterials] = useState(false);
  const [displayMaterialInfo, setDisplayMaterialInfo] = useState(null);
  const [displayProbes, setDisplayProbes] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cameraAdjusted, setCameraAdjusted] = useState(false);
  const meshRef = useRef(null);
  const meshPartsRefs = useRef([]);
  const cameraControlsRef = useRef(null);

  useEffect(() => {
    loadModel(setModel, setIsLoading, setError);
  }, []);

  useEffect(() => {
    if (displayMaterials && hoveredMaterialId !== null) {
      const interval = setInterval(() => {
        setDisplayMaterialInfo(hoveredMaterialId);
      }, 2000);
      return () => {
        setDisplayMaterialInfo(null);
        clearInterval(interval);
      };
    } else {
      setDisplayMaterialInfo(null);
    }
  }, [hoveredMaterialId, displayMaterials, mousePosition]);

  function getMaterialColor(materialId, hovered) {
    if (displayMaterials) {
      const colorPalette = getPalette();
      const materialColorHex = colorPalette[materialId % colorPalette.length];
      if (hovered) {
        const materialColorRgb = hexToRgb(materialColorHex);
        const colorOffset = 192;

        materialColorRgb.r = (materialColorRgb.r + colorOffset) % 255;
        materialColorRgb.g = (materialColorRgb.g + colorOffset) % 255;
        materialColorRgb.b = (materialColorRgb.b + colorOffset) % 255;

        return rgbToHex(materialColorRgb.r, materialColorRgb.g, materialColorRgb.b);
      } else {
        return materialColorHex;
      }
    } else {
      return "#DDDDDD";
    }
  }

  return error ? (
    <ErrorAlert error={error} />
  ) : (
    <ContentBox
      sx={Object.assign(
        {
          position: "relative",
        },
        sx
      )}
    >
      <SettingsPanel
        opacity={opacity}
        opacityOnChange={(event, newValue) => setOpacity(newValue)}
        displayMaterials={displayMaterials}
        displayMaterialsOnChange={(event, checked) => setDisplayMaterials(checked)}
        displayProbes={displayProbes}
        displayProbesOnChange={(event, checked) => setDisplayProbes(checked)}
      />
      {displayMaterialInfo && (
        <InfoBox position={{ x: mousePosition.x + 10, y: mousePosition.y + 10 }}>
          <Typography sx={{ fontWeight: "bold" }}>{`${t(
            "material"
          )} ID: ${hoveredMaterialId}`}</Typography>
          {model.materials[hoveredMaterialId]?.description && (
            <Typography>{model.materials[hoveredMaterialId].description[i18n.language]}</Typography>
          )}
        </InfoBox>
      )}
      <Canvas>
        <PerspectiveCamera makeDefault={true} position={[0, 0, 5]} up={[0, 0, 1]} far={5000} />
        <CameraControls ref={cameraControlsRef} enableDamping={false} />
        <ambientLight intensity={0.2} />
        <directionalLight intensity={0.3} position={[0, 0, 5]} color="white" />
        {!isLoading && (
          <>
            <axesHelper args={[10]} />
            <group
              ref={meshRef}
              onUpdate={(self) => {
                if (self && !cameraAdjusted) {
                  const padding = 0.1;
                  cameraControlsRef.current?.fitToBox(self, true, {
                    cover: false,
                    paddingTop: padding,
                    paddingBottom: padding,
                    paddingLeft: padding,
                    paddingRight: padding,
                  });
                  setCameraAdjusted(true);
                }
              }}
            >
              <group>
                {model.mesh.buffers.map((groupBuffers, index) => {
                  if (groupBuffers.primitiveType === "traingles") {
                    return (
                      <mesh
                        key={index}
                        ref={(ref) => {
                          if (ref) {
                            meshPartsRefs.current[index] = ref;
                          }
                        }}
                        onPointerOver={(e) => {
                          setHoveredMeshIndex(index);
                          setHoveredMaterialId(groupBuffers.materialId);
                        }}
                        onPointerOut={(e) => {
                          setHoveredMeshIndex(null);
                          setHoveredMaterialId(null);
                        }}
                        onPointerMove={(e) => {
                          setMousePosition({
                            x: e.layerX,
                            y: e.layerY,
                          });
                        }}
                      >
                        <bufferGeometry>
                          <bufferAttribute
                            attach="attributes-position"
                            count={groupBuffers.vertexBuffer.length / 3}
                            array={groupBuffers.vertexBuffer}
                            itemSize={3}
                          />
                          {groupBuffers.normalBuffer && (
                            <bufferAttribute
                              attach="attributes-normal"
                              count={groupBuffers.normalBuffer.length / 3}
                              array={groupBuffers.normalBuffer}
                              itemSize={3}
                            />
                          )}
                          {groupBuffers.indexBuffer && (
                            <bufferAttribute
                              attach="index"
                              array={groupBuffers.indexBuffer}
                              count={groupBuffers.indexBuffer.length}
                              itemSize={1}
                            />
                          )}
                        </bufferGeometry>
                        <meshLambertMaterial
                          transparent
                          opacity={opacity / 100}
                          color={getMaterialColor(
                            groupBuffers.materialId,
                            hoveredMeshIndex === index
                          )}
                        />
                      </mesh>
                    );
                  } else if (groupBuffers.primitiveType === "lines") {
                    return (
                      <lineSegments>
                        <bufferGeometry>
                          <bufferAttribute
                            attach="attributes-position"
                            count={groupBuffers.vertexBuffer.length / 3}
                            array={groupBuffers.vertexBuffer}
                            itemSize={3}
                          />
                          {groupBuffers.indexBuffer && (
                            <bufferAttribute
                              attach="index"
                              array={groupBuffers.indexBuffer}
                              count={groupBuffers.indexBuffer.length}
                              itemSize={1}
                            />
                          )}
                        </bufferGeometry>
                        <lineBasicMaterial linewidth={1} color={0x000000} />
                      </lineSegments>
                    );
                  } else {
                    return null;
                  }
                })}
              </group>
              <group>
                {Object.values(model.probes).map(
                  (probe) =>
                    probe.type === "point_probe" && (
                      <mesh visible={displayProbes} key={probe.probe_id} position={probe.location}>
                        <sphereGeometry args={[1.5, 32, 16]} attach="geometry" />
                        <meshLambertMaterial color="#00FFFF" />
                        {displayProbes && (
                          <Html>
                            <Typography noWrap>{getProbeLabel(probe, i18n)}</Typography>
                          </Html>
                        )}
                      </mesh>
                    )
                )}
              </group>
            </group>
          </>
        )}
      </Canvas>
      {isLoading && (
        <CircularProgressBox
          backgroundColor={theme.palette.background.dark}
          sx={{ position: "absolute" }}
        />
      )}
    </ContentBox>
  );
};

function getPalette() {
  return [
    "#FF0000",
    "#00FF00",
    "#0000FF",
    "#FFFF00",
    "#00FFFF",
    "#FF00FF",
    "#DD0000",
    "#00DD00",
    "#0000DD",
    "#DDDD00",
    "#00DDDD",
    "#DD00DD",
  ];
}

export default Viewer;
