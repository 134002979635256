import { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Switch,
  Checkbox,
  Button,
  Typography,
  Popover,
  FormControl,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../context/AuthProvider";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DateRangePicker from "../global/DateRangePicker";

const GraphSettings = ({
  displayOriginalMeasurement,
  setDisplayOriginalMeasurement,
  displayFilteredMeasurement,
  setDisplayFilteredMeasurement,
  displaySimulation,
  setDisplaySimulation,
  displayDifference,
  setDisplayDifference,
  dateRange,
  setDateRange,
  customQuantityRange,
  setCustomQuantityRange,
  quantityRange,
  setQuantityRange,
  defaultQuantityRange,
  customSimulationQuantityOffset,
  setCustomSimulationQuantityOffset,
  simulationQuantityOffset,
  setSimulationQuantityOffset,
  defaultSimulationQuantityOffset,
}) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [displayOriginalMeasurementLocal, setDisplayOriginalMeasurementLocal] = useState(
    displayOriginalMeasurement
  );
  const [displayFilteredMeasurementLocal, setDisplayFilteredMeasurementLocal] = useState(
    displayFilteredMeasurement
  );
  const [displaySimulationLocal, setDisplaySimulationLocal] = useState(displaySimulation);
  const [displayDifferenceLocal, setDisplayDifferenceLocal] = useState(displayDifference);

  const [startDate, setStartDate] = useState(dateRange.start);
  const [endDate, setEndDate] = useState(dateRange.end);

  const [customQuantityRangeLocal, setCustomQuantityRangeLocal] = useState(customQuantityRange);

  const [minQuantityValueLocal, setMinQuantityValueLocal] = useState(
    quantityRange.min !== undefined ? quantityRange.min : ""
  );
  const [maxQuantityValueLocal, setMaxQuantityValueLocal] = useState(
    quantityRange.max !== undefined ? quantityRange.max : ""
  );
  const [minQuantityValueError, setMinQuantityValueError] = useState(null);
  const [maxQuantityValueError, setMaxQuantityValueError] = useState(null);

  const [customSimulationQuantityOffsetLocal, setCustomSimulationQuantityOffsetLocal] = useState(
    customSimulationQuantityOffset
  );

  const [simulationQuantityOffsetLocal, setSimulationQuantityOffsetLocal] =
    useState(simulationQuantityOffset);
  const [simulationQuantityOffsetError, setSimulationQuantityOffsetError] = useState(null);

  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);

  useEffect(() => {
    setDisplayOriginalMeasurementLocal(displayOriginalMeasurement);
  }, [displayOriginalMeasurement]);

  useEffect(() => {
    setDisplayFilteredMeasurementLocal(displayFilteredMeasurement);
  }, [displayFilteredMeasurement]);

  useEffect(() => {
    setDisplaySimulationLocal(displaySimulation);
  }, [displaySimulation]);

  useEffect(() => {
    setDisplayDifferenceLocal(displayDifference);
  }, [displayDifference]);

  useEffect(() => {
    setCustomQuantityRangeLocal(customQuantityRange);
  }, [customQuantityRange]);

  useEffect(() => {
    setMinQuantityValueLocal(quantityRange.min !== undefined ? quantityRange.min : "");
    setMaxQuantityValueLocal(quantityRange.max !== undefined ? quantityRange.max : "");
    setMinQuantityValueError(null);
    setMaxQuantityValueError(null);
  }, [quantityRange]);

  useEffect(() => {
    setCustomSimulationQuantityOffsetLocal(customSimulationQuantityOffset);
  }, [customSimulationQuantityOffset]);

  useEffect(() => {
    setSimulationQuantityOffsetLocal(simulationQuantityOffset);
    setSimulationQuantityOffsetError(null);
  }, [simulationQuantityOffset]);

  const openSettings = Boolean(settingsAnchorEl);
  const settingsId = openSettings ? "settings-popover" : undefined;

  const openGraphSettings = (event) => {
    setSettingsAnchorEl(event.currentTarget);
  };

  const closeHandler = () => {
    if (areSettingsValid()) {
      setSettingsAnchorEl(null);

      if (displayOriginalMeasurement !== displayOriginalMeasurementLocal)
        setDisplayOriginalMeasurement(displayOriginalMeasurementLocal);
      if (displayFilteredMeasurement !== displayFilteredMeasurementLocal)
        setDisplayFilteredMeasurement(displayFilteredMeasurementLocal);
      if (displaySimulation !== displaySimulationLocal)
        setDisplaySimulation(displaySimulationLocal);
      if (displayDifference !== displayDifferenceLocal)
        setDisplayDifference(displayDifferenceLocal);

      if (startDate !== dateRange.start || endDate !== dateRange.end) {
        setDateRange({
          start: startDate,
          end: endDate,
        });
      }
      setCustomQuantityRange(customQuantityRangeLocal);
      setQuantityRange({
        min: Number(minQuantityValueLocal),
        max: Number(maxQuantityValueLocal),
      });
      setCustomSimulationQuantityOffset(customSimulationQuantityOffsetLocal);
      setSimulationQuantityOffset(Number(simulationQuantityOffsetLocal));
    }
  };

  function areSettingsValid() {
    let isCustomQuantityRangeValid = true;
    if (customQuantityRangeLocal) {
      isCustomQuantityRangeValid = validateQuantityRange(
        minQuantityValueLocal,
        maxQuantityValueLocal
      );
    }

    let isCustomSimulationQuantityOffsetValid = true;
    if (customSimulationQuantityOffsetLocal) {
      isCustomSimulationQuantityOffsetValid = validateSimulationQuantityOffset(
        simulationQuantityOffsetLocal
      );
    }
    return isCustomQuantityRangeValid && isCustomSimulationQuantityOffsetValid;
  }

  function validateQuantityRange(minValueOrig, maxValueOrig) {
    const minValue = Number(minValueOrig);
    const maxValue = Number(maxValueOrig);

    const isMinValueNumber = minValueOrig !== "" && !Number.isNaN(minValue);
    const isMaxValueNumber = maxValueOrig !== "" && !Number.isNaN(maxValue);

    let isValid = true;

    if (!isMinValueNumber) {
      if (minValueOrig === "") {
        setMinQuantityValueError(t("enter_value"));
      } else {
        setMinQuantityValueError(t("invalid_number"));
      }
      isValid = false;
    }

    if (!isMaxValueNumber) {
      if (maxValueOrig === "") {
        setMaxQuantityValueError(t("enter_value"));
      } else {
        setMaxQuantityValueError(t("invalid_number"));
      }
      isValid = false;
    }

    if (isMinValueNumber && !isMaxValueNumber) {
      setMinQuantityValueError(null);
      setMinQuantityValueLocal(minValue);
    }

    if (!isMinValueNumber && isMaxValueNumber) {
      setMaxQuantityValueError(null);
      setMaxQuantityValueLocal(maxValue);
    }

    if (isMinValueNumber && isMaxValueNumber) {
      if (maxValue <= minValue) {
        setMinQuantityValueError(t("max_must_be_greater_than_min"));
        setMaxQuantityValueError(t("max_must_be_greater_than_min"));
        isValid = false;
      } else {
        setMinQuantityValueError(null);
        setMaxQuantityValueError(null);
      }
    }
    return isValid;
  }

  function validateSimulationQuantityOffset(offsetOrig) {
    const offset = Number(offsetOrig);
    const isOffsetNumber = offsetOrig !== "" && !Number.isNaN(offset);

    if (isOffsetNumber) {
      setSimulationQuantityOffsetError(null);
      return true;
    } else {
      setSimulationQuantityOffsetError(t("invalid_number"));
      return false;
    }
  }

  return (
    <>
      <Button
        sx={{ mb: "10px", mr: "10px" }}
        aria-describedby={settingsId}
        variant="outlined"
        onClick={openGraphSettings}
        startIcon={<SettingsOutlinedIcon />}
      >
        {t("graph_settings")}
      </Button>
      <Popover
        id={settingsId}
        open={openSettings}
        anchorEl={settingsAnchorEl}
        onClose={closeHandler}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ m: "20px" }}>
          <Typography>{t("signal_types")}</Typography>
          <FormControl>
            <FormGroup sx={{ mb: "15px", flexDirection: "row", flexWrap: "wrap" }}>
              <FormGroup sx={{ flexDirection: "column" }}>
                <FormControlLabel
                  label={t("measurement")}
                  control={
                    <Switch
                      checked={displayOriginalMeasurementLocal}
                      onChange={(event) => {
                        setDisplayOriginalMeasurementLocal(event.target.checked);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={t("simulation")}
                  control={
                    <Switch
                      checked={displaySimulationLocal}
                      onChange={(event) => {
                        setDisplaySimulationLocal(event.target.checked);
                      }}
                    />
                  }
                />
              </FormGroup>
              <FormGroup sx={{ flexDirection: "column" }}>
                {auth && auth.isAdmin && (
                  <FormControlLabel
                    label={t("filtered_measurement")}
                    control={
                      <Switch
                        checked={displayFilteredMeasurementLocal}
                        onChange={(event) => {
                          setDisplayFilteredMeasurementLocal(event.target.checked);
                        }}
                      />
                    }
                  />
                )}
                <FormControlLabel
                  label={`${t("difference")} (${t("measurement")} - ${t("simulation")})`}
                  control={
                    <Switch
                      checked={displayDifferenceLocal}
                      onChange={(event) => {
                        setDisplayDifferenceLocal(event.target.checked);
                      }}
                    />
                  }
                />
              </FormGroup>
            </FormGroup>
          </FormControl>

          <Typography>{t("time_range")}</Typography>
          <DateRangePicker
            sx={{ mt: "10px", mb: "15px" }}
            startDate={startDate}
            endDate={endDate}
            startDateOnChange={(newValue) => {
              setStartDate(newValue);
            }}
            endDateOnChange={(newValue) => {
              setEndDate(newValue);
            }}
          />

          <FormControl>
            <FormGroup sx={{ mb: "0px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customQuantityRangeLocal}
                    onChange={(event, checked) => {
                      setCustomQuantityRangeLocal(checked);
                      if (!checked) {
                        setMinQuantityValueError(null);
                        setMaxQuantityValueError(null);
                        setMinQuantityValueLocal(
                          defaultQuantityRange.min !== undefined ? defaultQuantityRange.min : ""
                        );
                        setMaxQuantityValueLocal(
                          defaultQuantityRange.max !== undefined ? defaultQuantityRange.max : ""
                        );
                      }
                    }}
                  />
                }
                label={t("custom_quantity_range")}
              />
            </FormGroup>
          </FormControl>
          <Box display="flex" sx={{ alignItems: "top", mb: "20px" }}>
            <TextField
              disabled={!customQuantityRangeLocal}
              sx={{ maxWidth: "200px" }}
              value={minQuantityValueLocal}
              label={t("quantity_min_value")}
              error={minQuantityValueError !== null}
              helperText={minQuantityValueError}
              onChange={(event) => {
                setMinQuantityValueLocal(event.target.value);
              }}
            ></TextField>
            <Typography component="p" variant="h4" sx={{ ml: "5px", mr: "5px", mt: "10px" }}>
              —
            </Typography>
            <TextField
              disabled={!customQuantityRangeLocal}
              sx={{ maxWidth: "200px" }}
              value={maxQuantityValueLocal}
              label={t("quantity_max_value")}
              error={maxQuantityValueError !== null}
              helperText={maxQuantityValueError}
              onChange={(event) => {
                setMaxQuantityValueLocal(event.target.value);
              }}
            ></TextField>
          </Box>

          <FormControl>
            <FormGroup sx={{ mb: "0px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customSimulationQuantityOffsetLocal}
                    onChange={(event, checked) => {
                      setCustomSimulationQuantityOffsetLocal(checked);
                      if (!checked) {
                        setSimulationQuantityOffsetError(null);
                        setSimulationQuantityOffsetLocal(defaultSimulationQuantityOffset);
                      }
                    }}
                  />
                }
                label={t("custom_simulation_quantity_offset")}
              />
            </FormGroup>
          </FormControl>
          <Box sx={{ mb: "15px" }}>
            <TextField
              disabled={!customSimulationQuantityOffsetLocal}
              sx={{ maxWidth: "200px" }}
              value={simulationQuantityOffsetLocal}
              label={t("simulation_quantity_offset")}
              error={simulationQuantityOffsetError !== null}
              helperText={simulationQuantityOffsetError}
              onChange={(event) => {
                setSimulationQuantityOffsetLocal(event.target.value);
              }}
            ></TextField>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
            <Button variant="contained" onClick={closeHandler}>
              OK
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default GraphSettings;
