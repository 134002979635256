export function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

export function rgbToHex(r, g, b) {
  return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
}

export function getMeshBuffers(mesh) {
  const nodes = mesh.nodes;
  const elements = mesh.elements;
  const elementTypes = mesh.element_types;

  const buffersMap = getBuffersMap(nodes, elements, elementTypes);

  const buffers = [];

  for (let [primitiveType, buffersForPrimitiveType] of buffersMap.entries()) {
    for (let [materialId, buffersForMaterial] of buffersForPrimitiveType.entries()) {
      buffers.push({
        primitiveType,
        materialId,
        vertexBuffer: new Float32Array(buffersForMaterial.vertexBuffer),
        indexBuffer:
          buffersForMaterial.indexBuffer && buffersForMaterial.indexBuffer.length > 0
            ? new Uint32Array(buffersForMaterial.indexBuffer)
            : undefined,
        normalBuffer:
          buffersForMaterial.normalBuffer && buffersForMaterial.normalBuffer.length > 0
            ? new Float32Array(buffersForMaterial.normalBuffer)
            : undefined,
      });
    }
  }

  return buffers;
}

function getBuffersMap(nodes, elements, elementTypes) {
  const buffersMap = new Map();

  Object.values(elements).forEach((element) => {
    const elementTypeId = element.element_type_id;
    const materialId = element.material_id;

    const elementBuffers = getElementBuffers(nodes, element, elementTypes[elementTypeId], true);

    if (elementBuffers) {
      const {
        primitiveType,
        vertexBuffer: elemVertexBuffer,
        indexBuffer: elemIndexBuffer,
        normalBuffer: elemNormalBuffer,
      } = elementBuffers;

      if (!buffersMap.has(primitiveType)) {
        buffersMap.set(primitiveType, new Map());
      }

      if (!buffersMap.get(primitiveType).has(materialId)) {
        buffersMap.get(primitiveType).set(materialId, {
          vertexBuffer: [],
          normalBuffer: [],
          indexBuffer: [],
        });
      }

      const { vertexBuffer, normalBuffer, indexBuffer } = buffersMap
        .get(primitiveType)
        .get(materialId);

      const verticesCount = vertexBuffer.length / 3.0;
      vertexBuffer.push(...elemVertexBuffer);

      if (elemNormalBuffer) {
        normalBuffer.push(...elemNormalBuffer);
      }

      if (elemIndexBuffer) {
        indexBuffer.push(...elemIndexBuffer.map((idx) => idx + verticesCount));
      }
    }
  });

  return buffersMap;
}

function getElementBuffers(nodes, element, elementType, onlyExteriorNodes = false) {
  let elemGraphicsData = getElementGraphicsData(element, elementType);
  if (!elemGraphicsData) {
    return;
  }

  if (onlyExteriorNodes) {
    const exteriorNodesIds = [];
    element.nodes
      .map((nodeId) => nodes[nodeId])
      .forEach((node, index) => {
        if (node.is_exterior) {
          exteriorNodesIds.push(index);
        }
      });
    elemGraphicsData = filterNodes(elemGraphicsData, exteriorNodesIds);
  }

  const { primitiveType, nodesIdsForVertexBuffer, nodesIdsForInPlaneVectors, indexBuffer } =
    elemGraphicsData;

  const vertexBuffer = [];
  nodesIdsForVertexBuffer.forEach((faceNodesIdsForVertexBuffer) => {
    faceNodesIdsForVertexBuffer.forEach((nodeId) => {
      const node = nodes[element.nodes[nodeId]];

      vertexBuffer.push(node.x);
      vertexBuffer.push(node.y);
      vertexBuffer.push(node.z);
    });
  });

  let normalBuffer;
  if (nodesIdsForInPlaneVectors) {
    normalBuffer = [];
    nodesIdsForInPlaneVectors.forEach((faceNodesIdsForInPlaneVectors) => {
      faceNodesIdsForInPlaneVectors.forEach((nodesIds) => {
        const firstNode = nodes[element.nodes[nodesIds[0]]];
        const vertexNode = nodes[element.nodes[nodesIds[1]]];
        const secondNode = nodes[element.nodes[nodesIds[2]]];

        const normalVector = getNormalVector(firstNode, vertexNode, secondNode);
        normalBuffer.push(...normalVector);
      });
    });
  }

  const indexBufferConcatenated = [];
  indexBuffer.forEach((faceIndexBuffer) => {
    indexBufferConcatenated.push(...faceIndexBuffer);
  });

  return {
    primitiveType,
    vertexBuffer,
    indexBuffer: indexBufferConcatenated,
    normalBuffer,
  };
}

function getNormalVector(firstNode, vertexNode, secondNode) {
  const vectorA = [
    firstNode.x - vertexNode.x,
    firstNode.y - vertexNode.y,
    firstNode.z - vertexNode.z,
  ];

  const vectorB = [
    secondNode.x - vertexNode.x,
    secondNode.y - vertexNode.y,
    secondNode.z - vertexNode.z,
  ];

  const normalVector = [
    vectorA[1] * vectorB[2] - vectorA[2] * vectorB[1],
    vectorA[2] * vectorB[0] - vectorA[0] * vectorB[2],
    vectorA[0] * vectorB[1] - vectorA[1] * vectorB[0],
  ];

  return normalVector;
}

function filterNodes(elemGraphicsData, filteredNodesIds) {
  const { primitiveType, nodesIdsForVertexBuffer, nodesIdsForInPlaneVectors, indexBuffer } =
    elemGraphicsData;

  const filteredNodesIdsSet = new Set(filteredNodesIds);
  const { nodesIdsForVertexBufferFiltered, filteredVertexIds } = filterNodesIdsForVertexBuffer(
    nodesIdsForVertexBuffer,
    filteredNodesIdsSet
  );
  const nodesIdsForInPlaneVectorsFiltered = filterNodesIdsForInPlaneVectors(
    nodesIdsForInPlaneVectors,
    filteredNodesIdsSet
  );

  const indexBufferFiltered = filterIndexBuffer(indexBuffer, filteredVertexIds);

  return {
    primitiveType,
    nodesIdsForVertexBuffer: nodesIdsForVertexBufferFiltered,
    nodesIdsForInPlaneVectors: nodesIdsForInPlaneVectorsFiltered,
    indexBuffer: indexBufferFiltered,
  };
}

function filterNodesIdsForVertexBuffer(nodesIdsForVertexBuffer, filteredNodeIdsSet) {
  const nodesIdsForVertexBufferFiltered = [];
  const filteredVertexIds = new Set();
  let vertexIndex = 0;
  nodesIdsForVertexBuffer.forEach((faceNodesIdsForVertexBuffer) => {
    const keepFace = faceNodesIdsForVertexBuffer.every((nodeId) => filteredNodeIdsSet.has(nodeId));
    if (keepFace) {
      nodesIdsForVertexBufferFiltered.push(faceNodesIdsForVertexBuffer);
      faceNodesIdsForVertexBuffer.forEach(() => {
        filteredVertexIds.add(vertexIndex++);
      });
    } else {
      vertexIndex += faceNodesIdsForVertexBuffer.length;
    }
  });

  return { nodesIdsForVertexBufferFiltered, filteredVertexIds };
}

function filterNodesIdsForInPlaneVectors(nodesIdsForInPlaneVectors, filteredNodeIdsSet) {
  const nodesIdsForInPlaneVectorsFiltered = [];
  nodesIdsForInPlaneVectors.forEach((faceNodesIdsForInPlaneVectors, faceIdx) => {
    const keepFace = faceNodesIdsForInPlaneVectors.every((nodesIds) =>
      nodesIds.every((nodeId) => filteredNodeIdsSet.has(nodeId))
    );
    if (keepFace) {
      nodesIdsForInPlaneVectorsFiltered.push(faceNodesIdsForInPlaneVectors);
    }
  });

  return nodesIdsForInPlaneVectorsFiltered;
}

function filterIndexBuffer(indexBuffer, filteredVertexIds) {
  const indicesReductionMap = getIndicesReductionMap(filteredVertexIds);
  const indexBufferFiltered = [];
  indexBuffer.forEach((faceIndexBuffer) => {
    let keepFace = faceIndexBuffer.every((index) => filteredVertexIds.has(index));
    if (keepFace) {
      indexBufferFiltered.push(faceIndexBuffer.map((index) => indicesReductionMap.get(index)));
    }
  });

  return indexBufferFiltered;
}

function getIndicesReductionMap(indicesSet) {
  const reductionMap = new Map();
  const maxIndex = Math.max(...indicesSet);
  let offset = 0;
  for (let index = 0; index <= maxIndex; index++) {
    if (!indicesSet.has(index)) {
      offset++;
    }
    reductionMap.set(index, index - offset);
  }
  return reductionMap;
}

function getElementGraphicsData(element, elementType) {
  switch (elementType.name) {
    case "Hex8":
      return getElementGraphicsDataOfHex8(element);
    case "Line2":
      return getElementGraphicsDataOfLine2(element);
    default:
      console.error(`Element of type ${elementType.name} is not supported.`);
      return null;
  }
}

function getElementGraphicsDataOfHex8(element) {
  const primitiveType = "traingles";

  switch (element.nodes.length) {
    case 8:
      return {
        primitiveType,

        // prettier-ignore
        nodesIdsForVertexBuffer : [
            [0, 1, 2, 3],
            [0, 1, 5, 4],
            [1, 2, 6, 5],
            [2, 3, 7, 6],
            [3, 0, 4, 7],
            [4, 5, 6, 7]
        ],

        // prettier-ignore
        nodesIdsForInPlaneVectors: [
            [[3, 0, 1],
            [0, 1, 2],
            [1, 2, 3],
            [2, 3, 0]],
    
            [[1, 0, 4],
            [5, 1, 0],
            [4, 5, 1],
            [0, 4, 5]],
    
            [[2, 1, 5],
            [6, 2, 1],
            [5, 6, 2],
            [1, 5, 6]],
    
            [[3, 2, 6],
            [7, 3, 2],
            [6, 7, 3],
            [2, 6, 7]],
    
            [[0, 3, 7],
            [4, 0, 3],
            [7, 4, 0],
            [3, 7, 4]],
    
            [[5, 4, 7],
            [6, 5, 4],
            [7, 6, 5],
            [4, 7, 6]]
        ],

        // prettier-ignore
        indexBuffer: [
            [0, 2, 1, 0, 3, 2],
            [4, 5, 6, 4, 6, 7],
            [8, 9, 10, 8, 10, 11],
            [12, 13, 15, 13, 14, 15],
            [16, 17, 19, 17, 18, 19],
            [20, 21, 22, 20, 22, 23],
        ],
      };
    case 6:
      return {
        primitiveType,

        // prettier-ignore
        nodesIdsForVertexBuffer : [
                [0, 1, 2],
                [0, 1, 4, 3],
                [1, 2, 5, 4],
                [2, 0, 3, 5],
                [3, 4, 5]
            ],

        // prettier-ignore
        nodesIdsForInPlaneVectors: [
                [[2, 0, 1],
                [0, 1, 2],
                [1, 2, 0]],
        
                [[1, 0, 3],
                [4, 1, 0],
                [3, 4, 1],
                [0, 3, 4]],
        
                [[2, 1, 4],
                [5, 2, 1],
                [4, 5, 2],
                [1, 4, 5]],
        
                [[0, 2, 5],
                [3, 0, 2],
                [5, 3, 0],
                [2, 5, 3]],
        
                [[4, 3, 5],
                [5, 4, 3],
                [3, 5, 4]]
            ],

        // prettier-ignore
        indexBuffer: [
                [0, 2, 1],
                [3, 4, 5, 3, 5, 6],
                [7, 8, 9, 7, 9, 10],
                [11, 12, 14, 12, 13, 14],
                [15, 16, 17]
            ],
      };
    default:
      console.error(
        `Element of type Hex8 with ${element.nodes.length} number of nodes is not supported.`
      );
      return null;
  }
}

function getElementGraphicsDataOfLine2(element) {
  const primitiveType = "lines";

  switch (element.nodes.length) {
    case 2:
      return {
        primitiveType,
        nodeVertexIndices: [0, 1],
        elemIndexBuffer: [0, 1],
      };
    default:
      console.error(
        `Element of type Line2 with ${element.nodes.length} number of nodes is not supported.`
      );
      return null;
  }
}
