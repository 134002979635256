import { useMemo, useEffect, useState } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import LineChart from "./LineChart";
import CircularProgressBox from "./CircularProgressBox";
import { fetchSimulationSignal } from "../../../utils/data-provider";
import { AlertSnackbar } from "../../global/Info";
import Gauge from "./Gauge";
import { ErrorAlert } from "../../global/Info";
import { getProbeLabel } from "../../../utils/text";

const SafetyProbeChart = ({
  probe,
  loading,
  safetyRanges,
  safetyName,
  dateRange,
  colors,
  height,
  sx,
}) => {
  const [signal, setSignal] = useState(null);
  const [loadingSignal, setLoadingSignal] = useState(true);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const md = useMediaQuery("(min-width:992px)");

  useEffect(() => {
    if (probe) {
      setLoadingSignal(true);
      fetchSimulationSignal(probe.probe_id, dateRange)
        .then((signal) => {
          if (signal) {
            setSignal(signal);
          } else {
            setSignal(null);
            setWarning(
              i18n.t("no_simulation_signal_loaded", { probeName: getProbeLabel(probe, i18n) })
            );
          }
          setError(null);
        })
        .catch((error) => setError(error))
        .finally(() => setLoadingSignal(false));
    } else {
      setSignal(null);
    }
  }, [probe, dateRange, i18n]);

  const graphData = useMemo(() => {
    if (signal) {
      return [
        {
          name: `${probe.name} - ${t("simulation")}`,
          x: signal ? signal.times : [],
          y: signal ? signal.values : [],
          type: "scattergl",
          mode: "lines+markers",
          marker: { color: theme.palette.primary.main },
        },
      ];
    } else {
      return [];
    }
  }, [probe, signal, theme, t]);

  let lastValue;
  if (graphData && graphData.length > 0 && graphData[0].y.length > 0) {
    lastValue = graphData[0].y[graphData[0].y.length - 1];
  }

  if (!safetyName) {
    safetyName = probe ? getProbeLabel(probe, i18n) : "-";
  }

  if (error) {
    return (
      <Box sx={{ my: "5px" }}>
        <ErrorAlert error={error} />
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={Object.assign(
          {
            display: "flex",
            flexDirection: md ? "row" : "column",
            alignItems: "stretch",
          },
          sx
        )}
      >
        {loadingSignal || loading ? (
          <CircularProgressBox sx={{ height: height }} />
        ) : (
          <>
            <Box sx={{ flexGrow: 1, minWidth: 0, maxWidth: "310px", mx: "auto", width: "100%" }}>
              <Gauge
                id="gauge-chart1"
                valueName={safetyName}
                value={lastValue}
                ranges={safetyRanges}
                colors={colors}
              />
            </Box>
            <Box sx={{ flexGrow: 1, minWidth: 0, height: height }}>
              <LineChart
                data={graphData}
                margin={{ t: 30, b: 60, r: 20 }}
                xaxis={{
                  title: { text: t("time"), standoff: 5 },
                  range: [dateRange.start.toDate(), dateRange.end.toDate()],
                }}
                yaxis={{
                  title: { text: safetyName, standoff: 10 },
                  range: [safetyRanges[0], safetyRanges[safetyRanges.length - 1]],
                }}
              />
            </Box>
          </>
        )}
      </Box>
      <AlertSnackbar
        open={Boolean(warning)}
        msg={warning}
        closeFunc={() => setWarning(null)}
        severity="warning"
      />
    </>
  );
};

export default SafetyProbeChart;
