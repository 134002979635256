import { Box, useTheme } from "@mui/material";

const ContentBox = ({ children, sx }) => {
  const theme = useTheme();

  return (
    <Box sx={Object.assign({ backgroundColor: theme.palette.background.main }, sx)}>{children}</Box>
  );
};

export default ContentBox;
