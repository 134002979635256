import { Box, Button, Container, Grid, Typography, useTheme } from "@mui/material";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Home = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "calc(100% - 60px)",
        backgroundColor: theme.palette.background.main,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          <Box
            component="img"
            src="/images/EU.png"
            alt="Evropská unie"
            sx={{ width: "320px", height: "100px", ml: "-20px" }}
          />
          <Box
            component="img"
            src="/images/MPO.png"
            alt="Ministerstvo průmyslu a obchodu."
            sx={{ width: "180px", height: "100px" }}
          />
          <Box
            sx={{ mr: "50px" }}
            component="a"
            href="http://www.creacz.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src="images/Crea-hydro-and-energy.png"
              alt="CREA Hydro & Energy"
              sx={{ height: "80px" }}
            />
          </Box>
          <Box
            component="a"
            href="https://www.designtec.eu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              src="images/Designtec.svg"
              alt="CREA Hydro & Energy"
              sx={{ height: "80px" }}
            />
          </Box>
        </Box>
        <Grid container spacing={4} alignItems="center" sx={{ mt: "60px" }}>
          <Grid item xs={12} md={6}>
            <Typography variant="h1" sx={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
              {t("demonstrator_of_digital_twin_dam")}
            </Typography>
            <Typography variant="body1" mt="20px">
              {t("demonstrator_description")}
            </Typography>
            <Button
              component={Link}
              to="/login"
              startIcon={<LoginOutlinedIcon />}
              variant="contained"
              size="large"
              sx={{ mt: "40px" }}
            >
              {t("login")}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src="/images/Model-usum.png"
              alt="Model přehrady"
              sx={{ width: "100%", my: "20px" }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
