import { ThemeProvider } from "./theme";
import { CssBaseline } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Home from "./scenes/home";
import Login from "./scenes/login";
import Dashboard from "./scenes/dashboard";
import { PageNotFound } from "./scenes/global/Info";

function App() {
  const location = useLocation();
  const isInHomeLocation = location.pathname === "/";

  return (
    <ThemeProvider forceMode={isInHomeLocation && "light"}>
      <CssBaseline />
      <Topbar showThemeButton={!isInHomeLocation} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard/*" element={<Dashboard />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
