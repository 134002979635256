import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  Collapse,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import { useTranslation } from "react-i18next";
import Header from "../global/Header";
import { ErrorAlert } from "../../global/Info";
import ShowErrorButton from "./ShowErrorButton";
import ShowOutputButton from "./ShowOutputButton";
import { loadSolutionHistory } from "../../../utils/data-provider";
import CircularProgressBox from "../global/CircularProgressBox";

const Row = (props) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          "& .MuiTableCell-body": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell sx={{ borderBottom: "0px" }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.endDate.toLocaleDateString(i18n.language)}
        </TableCell>
        <TableCell scope="row">{row.endDate.toLocaleTimeString(i18n.language)}</TableCell>
        <TableCell align="center">
          {row.analyses_results.every((analysis) => analysis.successful) ? (
            <DoneOutlinedIcon sx={{ mt: "3px" }} />
          ) : (
            <ClearOutlinedIcon sx={{ mt: "3px" }} />
          )}
        </TableCell>
        <TableCell align="center">
          {row.system_error ? (
            <ShowErrorButton
              title={t("error_of_solution_step_ending", {
                endDate: row.endDate.toLocaleString(i18n.language),
              })}
              error={row.system_error}
            />
          ) : (
            <HorizontalRuleOutlinedIcon sx={{ mt: "3px" }} />
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={5}
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottomColor: theme.palette.background.dark,
          }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="analyses results">
                <TableHead>
                  <TableRow
                    sx={{
                      "& .MuiTableCell-head": {
                        borderBottomColor: theme.palette.background.dark,
                      },
                    }}
                  >
                    <TableCell>{t("analysis_name")}</TableCell>
                    <TableCell align="center" sx={{ width: "100px" }}>
                      {t("status")}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "100px" }}>
                      {t("output")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.analyses_results.map((analysisRow, index, array) => (
                    <TableRow
                      key={analysisRow.analysis_id}
                      sx={
                        index === array.length - 1
                          ? {
                              "& .MuiTableCell-body": {
                                borderBottom: "unset",
                              },
                            }
                          : {
                              "& .MuiTableCell-body": {
                                borderBottomColor: theme.palette.background.dark,
                              },
                            }
                      }
                    >
                      <TableCell component="th" scope="row">
                        {analysisRow.analysisName}
                      </TableCell>
                      <TableCell align="center">
                        {analysisRow.successful ? <DoneOutlinedIcon /> : <ClearOutlinedIcon />}
                      </TableCell>
                      <TableCell align="center">
                        <ShowOutputButton
                          title={`${t("output")}: ${
                            analysisRow.analysisName
                          } (${row.endDate.toLocaleString(i18n.language)})`}
                          stepId={row.step_id}
                          analysisId={analysisRow.analysis_id}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const SolutionHistory = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [solutionRecords, setSolutionRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadSolutionHistory(setSolutionRecords, setIsLoading, setError);
  }, []);

  return (
    <Box>
      <Header title={t("solution_history")} />
      {error ? (
        <ErrorAlert error={error} />
      ) : (
        <TableContainer
          sx={{
            width: "max-content",
            minWidth: "400px",
            height: "75vh",
            background: theme.palette.background.main,
            "& .MuiTableCell-stickyHeader": {
              background: theme.palette.background.main,
            },
          }}
        >
          {isLoading ? (
            <CircularProgressBox />
          ) : (
            <Table stickyHeader size="small" aria-label="solution history table">
              <TableHead>
                <TableRow
                  sx={{
                    "& .MuiTableCell-head": {
                      borderBottomColor: theme.palette.background.dark,
                    },
                  }}
                >
                  <TableCell />
                  <TableCell>{t("end_date")}</TableCell>
                  <TableCell>{t("end_time")}</TableCell>
                  <TableCell align="center" sx={{ width: "80px" }}>
                    {t("status")}
                  </TableCell>
                  <TableCell align="center" sx={{ width: "80px" }}>
                    {t("error")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {solutionRecords.map((record) => (
                  <Row key={record.step_id} row={record} />
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}
    </Box>
  );
};

export default SolutionHistory;
