import { useState, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { useTranslation } from "react-i18next";
import { ErrorAlert } from "../../global/Info";
import { fetchAnalysisResultOutput } from "../../../utils/data-provider";

const OutputMessageDialog = ({ open, setOpen, title, stepId, analysisId }) => {
  const { t } = useTranslation();
  const [output, setOutput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchAnalysisResultOutput(stepId, analysisId, setOutput, setIsLoading, setError);
  }, [stepId, analysisId]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {error ? (
          <ErrorAlert errorMessage={error.message} errorStatus={error.status} />
        ) : isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DialogContentText
            sx={{
              whiteSpace: "pre-wrap",
              fontFamily: "Consolas",
              fontSize: "12px",
            }}
          >
            {output}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

const ShowOutputButton = ({ title, stepId, analysisId }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("show_output")}>
        <IconButton size="small" onClick={() => setOpen(true)}>
          <ArticleOutlinedIcon />
        </IconButton>
      </Tooltip>
      {open && (
        <OutputMessageDialog
          title={title}
          stepId={stepId}
          analysisId={analysisId}
          open={open}
          setOpen={setOpen}
        />
      )}
    </>
  );
};

export default ShowOutputButton;
