import { useTheme } from "@mui/material";
import React, { useState } from "react";
import Plot from "react-plotly.js";
import Plotly from "plotly.js/lib/core";
import { useTranslation } from "react-i18next";
import { getColors } from "../../../theme";
import * as csDictionary from "plotly.js/lib/locales/cs.js";

function useForceUpdate() {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1); // update state to force render
}

const LineChart = ({
  data,
  title,
  autosize,
  width,
  height,
  margin,
  xaxis,
  yaxis,
  yaxis2,
  yaxis3,
  grid,
  showlegend,
  legend,
}) => {
  const theme = useTheme();
  const colors = getColors(theme.palette.mode);
  const { t, i18n } = useTranslation();
  const forceUpdate = useForceUpdate();

  const lineColor = theme.palette.mode === "light" ? colors.grey[900] : colors.grey[900];
  const gridColor = theme.palette.mode === "light" ? colors.grey[300] : colors.grey[500];

  const xaxisDefault = {
    type: "date",
    showgrid: true,
    linecolor: lineColor,
    gridcolor: gridColor,
    zeroline: false,
    showline: true,
  };

  const yaxisDefault = {
    title: {
      standoff: 30,
    },
    showgrid: true,
    linecolor: lineColor,
    gridcolor: gridColor,
    zeroline: false,
    showline: true,
  };

  return (
    <Plot
      data={data}
      layout={{
        autosize: autosize,
        hovermode: false,
        paper_bgcolor: "transparent",
        plot_bgcolor: "transparent",
        title: title,
        height: height,
        width: width,
        margin: margin,
        xaxis: getAxisSetting(xaxis, xaxisDefault),
        yaxis: getAxisSetting(yaxis, yaxisDefault),
        yaxis2: getAxisSetting(yaxis2, yaxisDefault),
        yaxis3: getAxisSetting(yaxis3, yaxisDefault),
        showlegend: showlegend,
        font: {
          family: theme.typography.fontFamily,
          size: theme.typography.graph.fontSize,
          color: theme.palette.text.primary,
        },
        modebar: {
          bgcolor: "transparent",
          color: theme.palette.primary.main,
          activecolor: theme.palette.primary.dark,
        },
        legend: legend,
        grid: grid,
      }}
      useResizeHandler={true}
      config={{
        staticPlot: false,
        displaylogo: false,
        locales: { cs: csDictionary },
        locale: i18n.language,
        modeBarButtonsToRemove: ["select2d", "lasso2d", "resetScale"],
        modeBarButtonsToAdd: [
          {
            name: "resetaxes",
            _cat: "resetscale",
            title: t("reset_axes"),
            attr: "zoom",
            icon: Plotly.Icons.home,
            click: function (gd) {
              forceUpdate();
            },
          },
        ],
      }}
      style={{ width: "100%", height: "100%" }}
    />
  );
};

function getAxisSetting(axisProp, defaultAxis) {
  if (!axisProp) {
    return defaultAxis;
  }

  return {
    ...defaultAxis,
    ...axisProp,
    range: axisProp.range ? [...axisProp.range] : undefined,
    title: {
      ...defaultAxis.title,
      ...axisProp.title,
    },
  };
}

export default LineChart;
