import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material";
import GaugeChart from "react-gauge-chart";

const Gauge = ({ id, valueName, value, ranges, colors, sx }) => {
  const theme = useTheme();
  let percent = computePercent(value, ranges);
  const currentColor = getCurrentColor(colors, ranges, value);

  return (
    <Box
      sx={Object.assign(
        {
          width: "100%",
          height: "100%",
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        },
        sx
      )}
    >
      <GaugeChart
        id={id}
        hideText={true}
        nrOfLevels={ranges && ranges.length > 1 ? ranges.length - 1 : undefined}
        colors={colors}
        needleColor={theme.palette.primary.main}
        needleBaseColor={theme.palette.primary.main}
        percent={percent ? percent : 0}
      />
      <Box>
        <Typography component="span" variant="body1" sx={{ textAlign: "center", mr: "5px" }}>
          {valueName}:
        </Typography>
        <Box
          sx={{
            display: "inline-block",
            backgroundColor: currentColor,
            py: "2px",
            px: "5px",
            borderRadius: "5px",
          }}
        >
          <Typography component="span" variant="body1" sx={{ textAlign: "center" }}>
            {value !== undefined ? value.toFixed(1) : "-"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

function computePercent(value, ranges) {
  if (!ranges || ranges.length < 2) {
    return value;
  }

  if (value <= ranges[0]) {
    return 0;
  }

  if (ranges[ranges.length - 1] <= value) {
    return 1;
  }

  const rangeLengthPercent = 1.0 / (ranges.length - 1);

  for (let i = 0; i < ranges.length - 1; i++) {
    const rangeStart = ranges[i];
    const rangeEnd = ranges[i + 1];

    if (rangeStart <= value && value <= rangeEnd) {
      const percentStart = i * rangeLengthPercent;
      const percentEnd = (i + 1) * rangeLengthPercent;

      return (
        ((percentEnd - percentStart) / (rangeEnd - rangeStart)) * (value - rangeStart) +
        percentStart
      );
    }
  }
}

function getCurrentColor(colors, ranges, value) {
  if (ranges.length === 0 || colors.length === 0 || value === undefined || value === null) {
    return null;
  }

  let colorIndex = -1;
  for (let i = 1; i < ranges.length; i++) {
    if (value <= ranges[i]) {
      colorIndex = i - 1;
      break;
    }
  }

  if (colorIndex === -1 && ranges[ranges.length - 1] < value) {
    colorIndex = ranges.length - 2;
  }

  if (colorIndex === -1 || colorIndex > colors.length - 1) {
    return null;
  }

  return colors[colorIndex];
}

export default Gauge;
