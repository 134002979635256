import {
  Avatar,
  Tooltip,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LigthModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useThemeModeUpdate } from "../../theme";
import { useAuth, useSetAuth } from "../../context/AuthProvider";
import HTTPError from "../../errors";

const menuPaperProps = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const Topbar = ({ showThemeButton }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const toggleThemeMode = useThemeModeUpdate();
  const auth = useAuth();

  return (
    <Box
      sx={{
        height: "60px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: theme.palette.background.main,
        verticalAlign: "center",
      }}
    >
      <Box sx={{ alignItems: "center", mr: "10px" }}>
        {showThemeButton && (
          <Tooltip title={t("color_theme")}>
            <IconButton onClick={toggleThemeMode} sx={{ m: "5px", p: "8px" }}>
              {theme.palette.mode === "dark" ? <LigthModeOutlinedIcon /> : <DarkModeOutlinedIcon />}
            </IconButton>
          </Tooltip>
        )}
        <LanguageSwitcher />
        {auth ? (
          <AccountButton />
        ) : (
          <Link to="/login" style={{ textDecoration: "none" }}>
            <IconButton sx={{ m: "5px" }}>
              <LoginOutlinedIcon sx={{ mr: "5px" }} />
              <Typography>{t("login")}</Typography>
            </IconButton>
          </Link>
        )}
      </Box>
    </Box>
  );
};

const LanguageSwitcher = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const { t, i18n } = useTranslation();

  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const open = Boolean(menuAnchorEl);

  return (
    <>
      <Tooltip title={t("language")}>
        <IconButton onClick={handleClick} size="small" sx={{ m: "5px", p: "8px" }}>
          <LanguageOutlinedIcon sx={{ mr: "5px" }} />
          <Typography>{i18n.resolvedLanguage}</Typography>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuPaperProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {i18n.options.languages.map((lng) => (
          <MenuItem
            key={lng}
            onClick={() => {
              handleClose();
              i18n.changeLanguage(lng);
            }}
          >
            {lng}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const AccountButton = () => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const setAuth = useSetAuth();
  const navigate = useNavigate();
  const auth = useAuth();
  const { t } = useTranslation();

  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const open = Boolean(menuAnchorEl);

  const logout = () => {
    fetch("/api/logout", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new HTTPError(response.status, response.statusText);
        } else {
          setAuth(null);
          navigate("/");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Tooltip title={t("account")}>
        <IconButton onClick={handleClick} size="small">
          <Avatar sx={{ width: 32, height: 32, bgcolor: theme.palette.primary.main }}>
            {auth.username[0]}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={menuAnchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={menuPaperProps}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/dashboard");
          }}
        >
          <ListItemIcon>
            <HomeOutlinedIcon />
          </ListItemIcon>
          {t("home")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            logout();
          }}
        >
          <ListItemIcon>
            <LogoutOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {t("logout")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Topbar;
