export function getProbeLabel(probe, i18n) {
  let label = probe.label?.[i18n.language];
  if (!label) label = probe.name ? probe.name : "";
  return label;
}

export function getProbeGroup(probe, i18n) {
  let group = probe.group?.[i18n.language];
  if (!group) group = i18n.t("others");
  return group;
}
