export function getSignalsDifference(simulation, measurement) {
  const diffSignal = {
    times: [],
    values: [],
  };

  for (let i = 0, j = 0; i < simulation.times.length - 1 && j < measurement.times.length; i++) {
    const simTime1 = new Date(simulation.times[i]);
    const simTime2 = new Date(simulation.times[i + 1]);
    const simValue1 = simulation.values[i];
    const simValue2 = simulation.values[i + 1];

    while (j < measurement.times.length) {
      const measTime = new Date(measurement.times[j]);
      if (simTime2 < measTime) break;
      if (measTime < simTime1) {
        j++;
        continue;
      }

      const measValue = measurement.values[j];
      const simValue = interpolate(simTime1, simValue1, simTime2, simValue2, measTime);

      diffSignal.times.push(measurement.times[j]);
      diffSignal.values.push(measValue - simValue);

      j++;
    }
  }
  return diffSignal;
}

function interpolate(x1, y1, x2, y2, x) {
  return ((y2 - y1) / (x2 - x1)) * (x - x1) + y1;
}
