import { Typography, Box, useTheme } from "@mui/material";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();

  return (
    <Box mb="20px">
      <Typography
        component="h1"
        variant="h2"
        sx={{
          color: theme.palette.primary.main,
          textTransform: "uppercase",
          fontWeight: "400",
        }}
      >
        {title}
      </Typography>
      {subtitle && <Typography variant="h5">{subtitle}</Typography>}
    </Box>
  );
};

export default Header;
