import { Box, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";

const DateRangePicker = ({ sx, startDate, endDate, startDateOnChange, endDateOnChange }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex" sx={Object.assign({ alignItems: "center" }, sx)}>
      <DatePicker
        label={t("start_date")}
        disableFuture
        maxDate={endDate}
        sx={{ maxWidth: "200px", minWidth: "145px" }}
        value={startDate}
        onChange={(newValue) => startDateOnChange(newValue)}
        slotProps={{
          actionBar: {
            actions: ["accept", "clear"],
          },
        }}
      />
      <Typography component="p" variant="h4" sx={{ ml: "5px", mr: "5px" }}>
        —
      </Typography>
      <DatePicker
        label={t("end_date")}
        disableFuture
        minDate={startDate}
        sx={{ maxWidth: "200px", minWidth: "145px" }}
        value={endDate}
        onChange={(newValue) => endDateOnChange(newValue)}
        slotProps={{
          actionBar: {
            actions: ["clear"],
          },
        }}
      />
    </Box>
  );
};

export default DateRangePicker;
