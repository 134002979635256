import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Header from "../global/Header";
import Viewer from "./Viewer";

const View = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Header title={t("view")} />
      <Viewer sx={{ height: "75vh" }} />
    </Box>
  );
};

export default View;
