import { Box, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import Header from "../global/Header";
import { ErrorAlert } from "../../global/Info";
import { loadProbes } from "../../../utils/data-provider";
import SafetyProbeChart from "../global/SafetyProbeChart";
import ContentBox from "../global/ContentBox";

const Home = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [probes, setProbes] = useState(null);
  const [loadingProbes, setLoadingProbes] = useState(false);
  const [probeError, setProbeError] = useState(null);

  const safetyProbes = useMemo(() => {
    return {
      1: "SF_MAX_TENSION_UPSTREAM",
      2: "SF_SLIDING_MIDDLE_Z_353.87",
      3: "SF_MAX_COMPRESSION_DOWNSTREAM",
    };
  }, []);

  const mode = theme.palette.mode;

  let safetyPlots = useMemo(() => {
    const green = mode === "dark" ? "#00FF00" : "#00c800";
    const orange = "#FFA500";
    const red = "#FF0000";

    return {
      1: {
        ranges: [-10, 1, 10],
        colors: [orange, green],
      },
      2: {
        ranges: [0, 1, 2, 10],
        colors: [red, orange, green],
      },
      3: {
        ranges: [0, 1, 2, 10],
        colors: [red, orange, green],
      },
    };
  }, [mode]);

  const dateRange = useMemo(() => {
    return {
      start: dayjs().subtract(7, "day").hour(0).minute(0).second(0).millisecond(0),
      end: dayjs().hour(0).minute(0).second(0).millisecond(0),
    };
  }, []);

  const safetyPlotsTitles = useMemo(() => {
    return {
      1: t("max_tension_upstream"),
      2: t("sliding_middle"),
      3: t("max_compression_downstream"),
    };
  }, [t]);

  useEffect(() => {
    loadProbes(setProbes, setLoadingProbes, setProbeError);
  }, []);

  return (
    <Box>
      <Header title={t("dashboard")} />
      {probeError ? (
        <ErrorAlert error={probeError} />
      ) : (
        <>
          {Object.entries(safetyProbes).map(([safetyGraphId, safetyProbe]) => (
            <ContentBox key={safetyGraphId} sx={{ mb: "10px" }}>
              <SafetyProbeChart
                probe={probes?.find((probe) => probe.name === safetyProbe)}
                height={"250px"}
                safetyRanges={safetyPlots[safetyGraphId].ranges}
                dateRange={dateRange}
                colors={safetyPlots[safetyGraphId].colors}
                safetyName={safetyPlotsTitles[safetyGraphId]}
                isLoading={loadingProbes}
              />
            </ContentBox>
          ))}
        </>
      )}
    </Box>
  );
};

export default Home;
