import { useState, useEffect } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Header from "../global/Header";
import { ErrorAlert } from "../../global/Info";
import { getTableStyle } from "../../../theme";
import { loadMaterials } from "../../../utils/data-provider";

const Materials = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const sm = useMediaQuery("(min-width: 768px)");
  const [materials, setMaterials] = useState([]);
  const [materialModels, setMaterialModels] = useState([]);
  const [modelParameters, setModelParameters] = useState([]);
  const [materialSelectionModel, setMaterialSelectionModel] = useState([]);
  const [materialModelSelectionModel, setMaterialModelSelectionModel] = useState([]);
  const [parameterSelectionModel, setParameterSelectionModel] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const tableStyle = getTableStyle(theme);

  useEffect(() => {
    loadMaterials(setMaterials, setIsLoading, setError);
  }, []);

  const materialsColumns = [
    { field: "material_id", headerName: "ID", width: 60 },
    {
      field: "description",
      headerName: t("description"),
      flex: 1,
      valueGetter: (params) => {
        return params?.value?.[i18n.language];
      },
    },
    { field: "density", headerName: `${t("density")} [kg/m3]`, width: 120, hide: !sm },
  ];

  const modelsColumns = [
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
      valueGetter: (params) => {
        return t(params.value);
      },
    },
    {
      field: "physicName",
      headerName: t("physics_type"),
      flex: 0.75,
      valueGetter: (params) => {
        return t(params.value);
      },
    },
  ];

  const parametersColumns = [
    {
      field: "name",
      headerName: t("name"),
      flex: 1,
      valueGetter: (params) => {
        return t(params.value);
      },
    },
    {
      field: "value",
      headerName: t("value"),
      flex: 0.75,
    },
  ];

  return (
    <Box>
      <Header title={t("materials")} />
      {error ? (
        <ErrorAlert error={error} />
      ) : (
        <>
          <Box height={sm ? "40vh" : "70vh"} sx={tableStyle}>
            <DataGrid
              density="compact"
              hideFooterSelectedRowCount
              getRowId={(row) => row.material_id}
              rows={materials}
              columns={materialsColumns}
              loading={isLoading}
              selectionModel={materialSelectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setParameterSelectionModel([]);
                setModelParameters([]);
                setMaterialModelSelectionModel([]);
                setMaterialModels([]);

                setMaterialSelectionModel(newSelectionModel);
                const material = materials.find((mat) => mat.material_id === newSelectionModel[0]);
                if (material) setMaterialModels(material.material_models);
              }}
              components={{ Toolbar: GridToolbar }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: sm ? "row" : "column",
              justifyContent: sm ? "space-between" : "flex-start",
            }}
          >
            <Box width={sm ? "49%" : "100%"} mt="20px" sx={Object.assign(tableStyle, {})}>
              <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                {t("material_models")}
              </Typography>
              <Box height={sm ? "35vh" : "50vh"}>
                <DataGrid
                  density="compact"
                  hideFooterSelectedRowCount
                  getRowId={(row) => row.material_model_type_id}
                  rows={materialModels}
                  columns={modelsColumns}
                  loading={isLoading}
                  selectionModel={materialModelSelectionModel}
                  onSelectionModelChange={(newSelectionModel) => {
                    setParameterSelectionModel([]);
                    setModelParameters([]);

                    setMaterialModelSelectionModel(newSelectionModel);
                    const matModel = materialModels.find(
                      (matModel) => matModel.material_model_type_id === newSelectionModel[0]
                    );
                    if (matModel) {
                      const parameters = Object.entries(matModel.parameters).map((elem, index) => {
                        return { id: index + 1, name: elem[0], value: elem[1] };
                      });
                      setModelParameters(parameters);
                    }
                  }}
                  components={{ Toolbar: GridToolbar }}
                />
              </Box>
            </Box>
            <Box width={sm ? "49%" : "100%"} mt="20px" sx={Object.assign(tableStyle, {})}>
              <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
                {t("model_parameters")}
              </Typography>
              <Box height={sm ? "35vh" : "50vh"}>
                <DataGrid
                  density="compact"
                  hideFooterSelectedRowCount
                  rows={modelParameters}
                  columns={parametersColumns}
                  loading={isLoading}
                  selectionModel={parameterSelectionModel}
                  onSelectionModelChange={(newSelectionModel) => {
                    setParameterSelectionModel(newSelectionModel);
                  }}
                  components={{ Toolbar: GridToolbar }}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Materials;
