import { useState, useEffect, useMemo } from "react";
import { Box, Autocomplete, TextField, useTheme } from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { AlertSnackbar, ErrorAlert, ErrorSnackbar } from "../../global/Info";
import Header from "../global/Header";
import LineChart from "../global/LineChart";
import {
  loadProbes,
  fetchOriginalMeasurementSignal,
  fetchFilteredMeasurementSignal,
  fetchSimulationSignal,
  fetchProbePlotPreferences,
} from "../../../utils/data-provider";
import { getSignalsDifference } from "../../../utils/signals";
import { useAuth } from "../../../context/AuthProvider";
import GraphSettings from "./GraphSettings";
import CircularProgressBox from "../global/CircularProgressBox";
import ContentBox from "../global/ContentBox";
import { getProbeGroup, getProbeLabel } from "../../../utils/text";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "6px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.65),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const SignalsData = () => {
  const theme = useTheme();
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const [probes, setProbes] = useState([]);
  const [loadingProbes, setLoadingProbes] = useState(false);

  const [selectedProbe, setSelectedProbe] = useState(null);

  const [originalMeasurementSignal, setOriginalMeasurementSignal] = useState(null);
  const [isOriginalMeasurementSignalLoading, setIsOriginalMeasurementSignalLoading] =
    useState(false);
  const [isOriginalMeasurementSignalVisible, setIsOriginalMeasurementSignalVisible] =
    useState(true);

  const [filteredMeasurementSignal, setFilteredMeasurementSignal] = useState(null);
  const [isFilteredMeasurementSignalLoading, setIsFilteredMeasurementSignalLoading] =
    useState(false);
  const [isFilteredMeasurementSignalVisible, setIsFilteredMeasurementSignalVisible] =
    useState(false);

  const [simulationSignal, setSimulationSignal] = useState(null);
  const [isSimulationSignalLoading, setIsSimulationSignalLoading] = useState(false);
  const [isSimulationSignalVisible, setIsSimulationSignalVisible] = useState(true);

  const [isDifferenceSignalVisible, setIsDifferenceSignalVisible] = useState(false);

  const [plotPreferences, setPlotPreferences] = useState(null);
  const [arePlotPreferencesLoading, setArePlotPreferencesLoading] = useState(false);

  const [probesError, setProbesError] = useState(null);

  const [infoAlert, setInfoAlert] = useState(null);
  const [errorAlert, setErrorAlert] = useState(null);

  const [dateRange, setDateRange] = useState({
    start: dayjs().subtract(7, "day").hour(0).minute(0).second(0).millisecond(0),
    end: dayjs(),
  });

  const [customQuantityRange, setCustomQuantityRange] = useState(false);
  const [quantityRange, setQuantityRange] = useState({
    min: undefined,
    max: undefined,
  });

  const [customSimulationQuantityOffset, setCustomSimulationQuantityOffset] = useState(false);
  const [simulationQuantityOffset, setSimulationQuantityOffset] = useState(0);

  const signalsVisibility = {
    originalMeasurement: isOriginalMeasurementSignalVisible,
    filteredMeasurement: isFilteredMeasurementSignalVisible,
    simulation: isSimulationSignalVisible,
    difference: isDifferenceSignalVisible,
  };

  const defaultQuantityRange = getDefaultQuantityRange(plotPreferences);
  const defaultSimulationQuantityOffset = plotPreferences
    ? plotPreferences.simulation_quantity_offset
    : 0;

  useEffect(() => {
    loadProbes(setProbes, setLoadingProbes, setProbesError);
  }, []);

  useEffect(() => {
    if (selectedProbe) {
      const probeId = selectedProbe.probe_id;
      setArePlotPreferencesLoading(true);
      fetchProbePlotPreferences(probeId)
        .then((plotPreferences) => setPlotPreferences(plotPreferences))
        .catch(() => {
          setPlotPreferences(null);
          console.warn(`Plot preferences not received for probe (ID: ${probeId}).`);
        })
        .finally(() => setArePlotPreferencesLoading(false));
    } else {
      setPlotPreferences(null);
    }
  }, [selectedProbe]);

  useEffect(() => {
    if (
      selectedProbe &&
      !originalMeasurementSignal &&
      (isOriginalMeasurementSignalVisible || isDifferenceSignalVisible)
    ) {
      const probeId = selectedProbe.probe_id;
      setIsOriginalMeasurementSignalLoading(true);
      fetchOriginalMeasurementSignal(probeId, dateRange)
        .then((signal) => {
          if (signal) {
            setOriginalMeasurementSignal(signal);
          } else {
            setInfoAlert(
              i18n.t("no_original_measurement_signal_loaded", {
                probeName: getProbeLabel(selectedProbe, i18n),
              })
            );
          }
          setErrorAlert(null);
        })
        .catch((error) => setErrorAlert(error))
        .finally(() => setIsOriginalMeasurementSignalLoading(false));
    }
  }, [
    selectedProbe,
    originalMeasurementSignal,
    isOriginalMeasurementSignalVisible,
    isDifferenceSignalVisible,
    dateRange,
    i18n,
  ]);

  useEffect(() => {
    if (selectedProbe && !filteredMeasurementSignal && isFilteredMeasurementSignalVisible) {
      const probeId = selectedProbe.probe_id;
      setIsFilteredMeasurementSignalLoading(true);
      fetchFilteredMeasurementSignal(probeId, dateRange)
        .then((signal) => {
          if (signal) {
            setFilteredMeasurementSignal(signal);
          } else {
            setInfoAlert(
              i18n.t("no_filtered_measurement_signal_loaded", {
                probeName: getProbeLabel(selectedProbe, i18n),
              })
            );
          }
          setErrorAlert(null);
        })
        .catch((error) => setErrorAlert(error))
        .finally(() => setIsFilteredMeasurementSignalLoading(false));
    }
  }, [
    selectedProbe,
    filteredMeasurementSignal,
    isFilteredMeasurementSignalVisible,
    dateRange,
    i18n,
  ]);

  useEffect(() => {
    if (
      selectedProbe &&
      !simulationSignal &&
      (isSimulationSignalVisible || isDifferenceSignalVisible)
    ) {
      const probeId = selectedProbe.probe_id;
      setIsSimulationSignalLoading(true);
      fetchSimulationSignal(probeId, dateRange)
        .then((signal) => {
          if (signal) {
            setSimulationSignal(signal);
          } else {
            setInfoAlert(
              i18n.t("no_simulation_signal_loaded", {
                probeName: getProbeLabel(selectedProbe, i18n),
              })
            );
          }
          setErrorAlert(null);
        })
        .catch((error) => setErrorAlert(error))
        .finally(() => setIsSimulationSignalLoading(false));
    }
  }, [
    selectedProbe,
    simulationSignal,
    isSimulationSignalVisible,
    isDifferenceSignalVisible,
    dateRange,
    i18n,
  ]);

  useEffect(() => {
    setQuantityRange(getDefaultQuantityRange(plotPreferences));
    setCustomQuantityRange(false);
    setSimulationQuantityOffset(plotPreferences ? plotPreferences.simulation_quantity_offset : 0);
    setCustomSimulationQuantityOffset(false);
  }, [plotPreferences]);

  useEffect(() => {
    setOriginalMeasurementSignal(null);
    setFilteredMeasurementSignal(null);
    setSimulationSignal(null);
  }, [dateRange]);

  const orderedProbes = useMemo(() => {
    if (!probes) return probes;

    return probes.sort((a, b) => {
      const groupA = getProbeGroup(a, i18n);
      const groupB = getProbeGroup(b, i18n);
      const groupCompare = groupA.localeCompare(groupB, i18n.language);
      if (groupCompare !== 0) {
        return groupCompare;
      }

      const labelA = getProbeLabel(a, i18n);
      const labelB = getProbeLabel(b, i18n);
      return labelA.localeCompare(labelB, i18n.language);
    });
  }, [probes, i18n]);

  const simulationSignalWithOffset = useMemo(() => {
    if (!simulationSignal || simulationQuantityOffset === 0) {
      return simulationSignal;
    }

    const valuesWithOffset = simulationSignal.values.map(
      (value) => value + simulationQuantityOffset
    );
    return {
      ...simulationSignal,
      values: valuesWithOffset,
    };
  }, [simulationSignal, simulationQuantityOffset]);

  const differenceSignal = useMemo(() => {
    if (originalMeasurementSignal && simulationSignalWithOffset) {
      return getSignalsDifference(simulationSignalWithOffset, originalMeasurementSignal);
    } else {
      return null;
    }
  }, [originalMeasurementSignal, simulationSignalWithOffset]);

  const signalsData = {
    originalMeasurement: originalMeasurementSignal,
    filteredMeasurement: filteredMeasurementSignal,
    simulation: simulationSignalWithOffset,
    difference: differenceSignal,
  };

  const graphData = getGraphData(selectedProbe, signalsData, signalsVisibility, auth, t);

  const graphLabelY = getLabelY(selectedProbe, t);
  const yAxisRange = getYAxisRange(defaultQuantityRange, customQuantityRange, quantityRange);
  const yAxisAutoRange = yAxisRange.some((value) => value === undefined);

  const isSignalLoading =
    isOriginalMeasurementSignalLoading ||
    isFilteredMeasurementSignalLoading ||
    isSimulationSignalLoading;

  return (
    <Box>
      <Header title={t("data")} />
      {probesError ? (
        <ErrorAlert error={probesError} />
      ) : (
        <ContentBox
          sx={{
            width: "100%",
            padding: "30px",
            mt: "20px",
            minWidth: "500px",
          }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            <Autocomplete
              loading={loadingProbes}
              disablePortal
              sx={{ maxWidth: 400, minWidth: 200, flexGrow: 1, mb: "10px", mr: "10px" }}
              value={selectedProbe}
              onChange={(event, newProbe) => {
                setSelectedProbe(newProbe);
                setOriginalMeasurementSignal(null);
                setFilteredMeasurementSignal(null);
                setSimulationSignal(null);
              }}
              options={orderedProbes}
              getOptionLabel={(probe) => getProbeLabel(probe, i18n)}
              isOptionEqualToValue={(option, value) => {
                return (
                  option.structure_id === value.structure_id &&
                  option.model_id === value.model_id &&
                  option.probe_id === value.probe_id
                );
              }}
              renderInput={(params) => <TextField {...params} label={t("probe")} />}
              groupBy={(probe) => getProbeGroup(probe, i18n)}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
            <GraphSettings
              displayOriginalMeasurement={isOriginalMeasurementSignalVisible}
              setDisplayOriginalMeasurement={setIsOriginalMeasurementSignalVisible}
              displayFilteredMeasurement={isFilteredMeasurementSignalVisible}
              setDisplayFilteredMeasurement={setIsFilteredMeasurementSignalVisible}
              displaySimulation={isSimulationSignalVisible}
              setDisplaySimulation={setIsSimulationSignalVisible}
              displayDifference={isDifferenceSignalVisible}
              setDisplayDifference={setIsDifferenceSignalVisible}
              dateRange={dateRange}
              setDateRange={setDateRange}
              customQuantityRange={customQuantityRange}
              setCustomQuantityRange={setCustomQuantityRange}
              quantityRange={quantityRange}
              setQuantityRange={setQuantityRange}
              defaultQuantityRange={defaultQuantityRange}
              customSimulationQuantityOffset={customSimulationQuantityOffset}
              setCustomSimulationQuantityOffset={setCustomSimulationQuantityOffset}
              simulationQuantityOffset={simulationQuantityOffset}
              setSimulationQuantityOffset={setSimulationQuantityOffset}
              defaultSimulationQuantityOffset={defaultSimulationQuantityOffset}
            />
          </Box>
          <Box sx={{ position: "relative", height: "65vh", minWidth: "0px" }}>
            {(isSignalLoading || arePlotPreferencesLoading) && (
              <CircularProgressBox
                backgroundColor={theme.palette.background.dark}
                sx={{ position: "absolute", zIndex: 100 }}
              />
            )}
            <LineChart
              data={graphData}
              title={{
                text: `<b>${selectedProbe ? getProbeLabel(selectedProbe, i18n) : ""}</b> <br> ${
                  selectedProbe?.description?.[i18n.language] || ""
                }`,
                y: 0.95,
              }}
              margin={{
                t: 70,
                b: 60,
              }}
              xaxis={{ title: { text: t("time") }, autorange: true, type: "date" }}
              yaxis={{ title: { text: graphLabelY }, autorange: yAxisAutoRange, range: yAxisRange }}
              yaxis2={{
                title: { text: `${t("difference")} (${t("measurement")} - ${t("simulation")})` },
                autorange: true,
                overlaying: "y",
                side:
                  isOriginalMeasurementSignalVisible ||
                  isFilteredMeasurementSignalVisible ||
                  isSimulationSignalVisible
                    ? "right"
                    : "left",
              }}
              showlegend={true}
              legend={{
                x: 0,
                y: 1,
              }}
            />
          </Box>
          <ErrorSnackbar error={errorAlert} unsetError={() => setErrorAlert(null)} />
          <AlertSnackbar
            open={Boolean(infoAlert)}
            msg={infoAlert}
            closeFunc={() => setInfoAlert(null)}
            severity="info"
          />
        </ContentBox>
      )}
    </Box>
  );
};

function getLabelY(selectedProbe, t) {
  let graphLabelY = "-";
  if (selectedProbe) {
    const unit = selectedProbe.unit === "" ? "-" : selectedProbe.unit;
    graphLabelY = `${t(selectedProbe.quantity)} [${unit}]`;
  }

  return graphLabelY;
}

function getDefaultQuantityRange(plotPreferences) {
  const defaultQuantityRange = {
    min: undefined,
    max: undefined,
  };

  if (
    plotPreferences &&
    plotPreferences.quantity_range_min !== undefined &&
    plotPreferences.quantity_range_min !== null &&
    plotPreferences.quantity_range_max !== undefined &&
    plotPreferences.quantity_range_max !== null
  ) {
    defaultQuantityRange.min = plotPreferences.quantity_range_min;
    defaultQuantityRange.max = plotPreferences.quantity_range_max;
  }

  return defaultQuantityRange;
}

function getYAxisRange(defaultQuantityRange, customQuantityRange, quantityRange) {
  let yAxisMin = defaultQuantityRange.min;
  let yAxisMax = defaultQuantityRange.max;

  if (customQuantityRange) {
    yAxisMin = quantityRange.min;
    yAxisMax = quantityRange.max;
  }
  return [yAxisMin, yAxisMax];
}

function getGraphData(probe, signalsData, signalsVisibility, auth, t) {
  const graphData = [];
  if (probe && signalsData) {
    if (signalsVisibility.originalMeasurement) {
      const originalMeasurement = signalsData.originalMeasurement;
      graphData.push({
        name: t("measurement"),
        x: originalMeasurement ? originalMeasurement.times : [],
        y: originalMeasurement ? originalMeasurement.values : [],
        type: "scattergl",
        mode: "markers",
        marker: { color: "#00B4E2", size: 3 },
      });
    }
    if (auth && auth.isAdmin && signalsVisibility.filteredMeasurement) {
      const filteredMeasurement = signalsData.filteredMeasurement;
      graphData.push({
        name: t("filtered_measurement"),
        x: filteredMeasurement ? filteredMeasurement.times : [],
        y: filteredMeasurement ? filteredMeasurement.values : [],
        type: "scattergl",
        mode: "lines+markers",
        marker: { color: "#E28800" },
      });
    }
    if (signalsVisibility.simulation) {
      const simulation = signalsData.simulation;
      graphData.push({
        name: t("simulation"),
        x: simulation ? simulation.times : [],
        y: simulation ? simulation.values : [],
        type: "scattergl",
        mode: "lines+markers",
        marker: { color: "#3BE200" },
      });
    }
    if (signalsVisibility.difference) {
      const difference = signalsData.difference;
      graphData.push({
        name: `${t("measurement")} - ${t("simulation")}`,
        x: difference ? difference.times : [],
        y: difference ? difference.values : [],
        type: "scattergl",
        mode: "lines+markers",
        yaxis: "y2",
        marker: { color: "#00FFFF" },
      });
    }
  }
  return graphData;
}

export default SignalsData;
