import { Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Sidebar from "./global/Sidebar";
import Home from "./home";
import View from "./view";
import Probes from "./probes";
import Materials from "./materials";
import SignalsData from "./signals";
import Profiles from "./profiles";
import SolutionHistory from "./solution-history";
import { PageNotFound } from "../global/Info";

const DashBoard = (props) => {
  return (
    <div className="app">
      <Sidebar />
      <Box component={"main"} className="contentContainer">
        <Routes>
          <Route index element={<Home />} />
          <Route path="view" element={<View />} />
          <Route path="probes" element={<Probes />} />
          <Route path="materials" element={<Materials />} />
          <Route path="data" element={<SignalsData />} />
          <Route path="profiles" element={<Profiles />} />
          <Route path="solution-history" element={<SolutionHistory />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Box>
    </div>
  );
};

export default DashBoard;
