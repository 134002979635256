import {
  Sidebar as ReactProSidebar,
  Menu,
  MenuItem,
  useProSidebar,
  sidebarClasses,
  menuClasses,
} from "react-pro-sidebar";
import { useTheme } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import SensorsOutlinedIcon from "@mui/icons-material/SensorsOutlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";
import AutoAwesomeMotionOutlinedIcon from "@mui/icons-material/AutoAwesomeMotionOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";

const MenuItemLink = ({ title, to, icon }) => {
  const location = useLocation();

  return (
    <MenuItem
      active={location.pathname.endsWith(to) || location.pathname.endsWith(to + "/")}
      icon={icon}
      component={<Link to={to} />}
    >
      {title && capitalizeFirstLetter(title)}
    </MenuItem>
  );
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const Sidebar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { collapseSidebar } = useProSidebar();

  const menuItemStyles = {
    button: ({ active }) => {
      return {
        backgroundColor: active ? theme.palette.background.dark : undefined,
      };
    },
  };
  return (
    <ReactProSidebar
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          backgroundColor: theme.palette.background.main,
        },
        ["." + menuClasses.button]: {
          "&:hover": {
            backgroundColor: theme.palette.background.dark,
          },
        },
        borderWidth: "0px",
      }}
    >
      <Menu menuItemStyles={menuItemStyles}>
        <MenuItem
          icon={<MenuOutlinedIcon />}
          onClick={() => {
            collapseSidebar();
          }}
        />

        <MenuItemLink title={t("home")} to="/dashboard" icon={<HomeOutlinedIcon />} />
        <MenuItemLink title={t("view")} to={"/dashboard/view"} icon={<ViewInArOutlinedIcon />} />
        <MenuItemLink title={t("probes")} to={"/dashboard/probes"} icon={<SensorsOutlinedIcon />} />
        <MenuItemLink
          title={t("materials")}
          to={"/dashboard/materials"}
          icon={<DomainOutlinedIcon />}
        />
        <MenuItemLink title={t("data")} to={"/dashboard/data"} icon={<SsidChartOutlinedIcon />} />
        <MenuItemLink
          title={t("profiles")}
          to={"/dashboard/profiles"}
          icon={<AutoAwesomeMotionOutlinedIcon />}
        />
        <MenuItemLink
          title={t("solution_history")}
          to={"/dashboard/solution-history"}
          icon={<WorkHistoryOutlinedIcon />}
        />
      </Menu>
    </ReactProSidebar>
  );
};

export default Sidebar;
