import { Box, CircularProgress } from "@mui/material";

const CircularProgressBox = ({ backgroundColor, sx }) => {
  return (
    <Box
      sx={Object.assign(
        {
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        sx
      )}
    >
      <Box
        sx={{
          backgroundColor: backgroundColor,
          padding: "30px",
          opacity: 0.8,
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
  );
};

CircularProgressBox.defaultProps = {
  backgroundColor: "transparent",
};

export default CircularProgressBox;
