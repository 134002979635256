import { useEffect } from "react";
import { Alert, AlertTitle, Box, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSetAuth } from "../../context/AuthProvider";

function useErrorHandling(error) {
  const setAuth = useSetAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      if (error.status === 401) {
        setAuth(null);
        navigate("/login");
      } else {
        (console.error || console.log).call(console, error.stack || error);
      }
    }
  }, [error, setAuth, navigate]);
}

export const ErrorAlert = ({ error }) => {
  useErrorHandling(error);

  return (
    <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      <Box display="flex">{error.message}</Box>
    </Alert>
  );
};

export const ErrorSnackbar = ({ error, unsetError }) => {
  useErrorHandling(error);

  return (
    <AlertSnackbar
      open={Boolean(error)}
      msg={error ? error.message : ""}
      closeFunc={() => unsetError()}
      severity="error"
    />
  );
};

export const AlertSnackbar = ({ open, msg, severity, closeFunc }) => {
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    if (open) {
      closeFunc();
    }
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleCloseSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: "100%" }}>
        {msg}
      </Alert>
    </Snackbar>
  );
};

export const PageNotFound = () => (
  <Box m="20px">
    <ErrorAlert error={{ status: 404, statusText: "Page not found." }} />
  </Box>
);
