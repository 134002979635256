import { useEffect, useState } from "react";
import { Box, Container, TextField, Button, Alert, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth, useSetAuth } from "../../context/AuthProvider";
import HTTPError from "../../errors";

const Login = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const setAuth = useSetAuth();
  const [username, setUsername] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setErrMsg("");
  }, [username, pwd]);

  useEffect(() => {
    if (auth) {
      navigate("/dashboard");
    }
  }, [auth, navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    fetch("/api/login", {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        username,
        password: pwd,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new HTTPError(response.status, response.statusText);
        } else {
          return response.json();
        }
      })
      .then((user) => {
        setUsername("");
        setPwd("");
        setAuth({ username: user.username, isAdmin: user.is_admin });
      })
      .catch((error) => {
        if (error.status === 400) {
          setErrMsg(t("missing_username_or_password"));
        } else if (error.status === 401) {
          setErrMsg(t("incorrect_username_or_password"));
        } else {
          setErrMsg(t("login_failed"));
        }
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "80%",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Container component="main" maxWidth="xs">
        {errMsg.length > 0 && (
          <Alert severity="error" sx={{ mb: "30px" }}>
            {errMsg}
          </Alert>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.palette.background.main,
            padding: "20px",
          }}
        >
          <Typography component="h1" variant="h3">
            {t("sign_in")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t("username")}
              name="username"
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t("password")}
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPwd(e.target.value)}
              value={pwd}
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {t("sign_in")}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Login;
