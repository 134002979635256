import { useState } from "react";
import {
  Button,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { useTranslation } from "react-i18next";

const ShowErrorDialog = ({ open, setOpen, title, error }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth={true} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: "pre-wrap" }}>{error}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

const ShowErrorButton = ({ title, error }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("show_error")}>
        <IconButton size="small" onClick={() => setOpen(true)}>
          <ErrorOutlineOutlinedIcon />
        </IconButton>
      </Tooltip>
      <ShowErrorDialog open={open} setOpen={setOpen} title={title} error={error} />
    </>
  );
};

export default ShowErrorButton;
