import { useState, useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Header from "../global/Header";
import { getTableStyle } from "../../../theme";
import ShowProbeDetailButton from "./ShowProbeDetailButton";
import { ErrorAlert } from "../../global/Info";
import { loadProbes } from "../../../utils/data-provider";
import { getProbeLabel, getProbeGroup } from "../../../utils/text";

const Probes = () => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const sm = useMediaQuery("(min-width: 768px)");
  const md = useMediaQuery("(min-width: 992px)");
  const [probes, setProbes] = useState([]);
  const [loadingProbes, setLoadingProbes] = useState(false);
  const [error, setError] = useState(null);

  const tableStyle = getTableStyle(theme);

  useEffect(() => {
    loadProbes(setProbes, setLoadingProbes, setError);
  }, []);

  const columns = [
    {
      field: "label",
      headerName: t("label"),
      flex: 2,
      valueGetter: (params) => {
        return getProbeLabel(params.row, i18n);
      },
    },
    {
      field: "group",
      headerName: t("group"),
      flex: 2,
      valueGetter: (params) => {
        return getProbeGroup(params.row, i18n);
      },
    },
    {
      field: "type",
      headerName: t("type"),
      flex: 1,
      hide: !md,
      valueGetter: (params) => {
        return t(params.value);
      },
    },
    {
      field: "quantity",
      headerName: t("quantity"),
      flex: 1,
      hide: !sm,
      valueGetter: (params) => {
        return t(params.value);
      },
    },
    {
      field: "unit",
      headerName: t("unit"),
      flex: 1,
      hide: !sm,
      valueGetter: (params) => {
        return params.value === "" ? "-" : params.value;
      },
    },
    {
      field: "is_input",
      type: "boolean",
      headerName: t("is_input"),
      width: 80,
      align: "center",
      headerAlign: "center",
      hide: !md,
    },
    {
      field: "detail",
      headerName: t("detail"),
      width: 80,
      align: "center",
      headerAlign: "center",
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => <ShowProbeDetailButton probe={row} />,
    },
  ];

  return (
    <Box>
      <Header title={t("probes")} />
      {error ? (
        <ErrorAlert error={error} />
      ) : (
        <>
          <Box mt="20px" height="75vh" sx={tableStyle}>
            <DataGrid
              density={sm ? "standard" : "compact"}
              hideFooterSelectedRowCount
              getRowId={(row) => row.probe_id}
              rows={probes}
              columns={columns}
              loading={loadingProbes}
              components={{ Toolbar: GridToolbar }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default Probes;
