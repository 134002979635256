const config = {
  api: {
    version: "v0",
    structureId: 1,
    modelId: 1,
    scenarioId: 1,
  },
};

export default config;
