import { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { getProbeLabel, getProbeGroup } from "../../../utils/text";

const ProbeDetailDialog = ({ open, setOpen, probe }) => {
  const detailNameStyle = { fontWeight: "bold", whiteSpace: "pre" };
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={open}>
      <DialogTitle>{t("detail_of_probe", { probeName: probe.name })}</DialogTitle>
      <DialogContent>
        <DialogContent>
          <Box display="flex">
            <Typography sx={detailNameStyle}>{`${t("label")}: `}</Typography>
            <Typography>{probe && getProbeLabel(probe, i18n)}</Typography>
          </Box>
          <Box display="flex">
            <Typography sx={detailNameStyle}>{`${t("group")}: `}</Typography>
            <Typography>{probe && getProbeGroup(probe, i18n)}</Typography>
          </Box>
          <Box display="flex">
            <Typography sx={detailNameStyle}>{`${t("type")}: `}</Typography>
            <Typography>{probe && t(probe.type)}</Typography>
          </Box>
          <Box display="flex">
            <Typography sx={detailNameStyle}>{`${t("quantity")}: `}</Typography>
            <Typography>{probe && t(probe.quantity)}</Typography>
          </Box>
          <Box display="flex">
            <Typography sx={detailNameStyle}>{`${t("unit")}: `}</Typography>
            <Typography>{probe && probe.unit}</Typography>
          </Box>
          <Box display="flex">
            <Typography sx={detailNameStyle}>{`${t("is_input")}: `}</Typography>
            <Typography>{probe && probe.is_input ? t("yes") : t("no")}</Typography>
          </Box>

          {probe.type === "point_probe" && (
            <Box display="flex">
              <Typography sx={detailNameStyle}>{`${t("location")}: `}</Typography>
              <Typography>{`[${probe.location[0]}; ${probe.location[1]}; ${probe.location[2]}]`}</Typography>
            </Box>
          )}
          {probe.type === "component_probe" && (
            <>
              <Box display="flex">
                <Typography sx={detailNameStyle}>{`${t("node_component")}: `}</Typography>
                <Typography>{probe.node_component}</Typography>
              </Box>
              <Box>
                <Typography sx={detailNameStyle}>{`${t("element_component")}: `}</Typography>
                <Typography>{probe.element_component}</Typography>
              </Box>
            </>
          )}
          {probe.pointProbes && (
            <Box display="flex">
              <Typography sx={detailNameStyle}>{`${t("point_probes")}: `}</Typography>
              <Typography>
                {probe.pointProbes.map((pointProbe) => getProbeLabel(pointProbe, i18n)).join(", ")}
              </Typography>
            </Box>
          )}
          {probe.multiProbes && (
            <Box display="flex">
              <Typography sx={detailNameStyle}>{`${t("probes")}: `}</Typography>
              <Typography>
                {probe.multiProbes.map((probe) => getProbeLabel(probe, i18n)).join(", ")}
              </Typography>
            </Box>
          )}
          <Box display="flex">
            <Typography sx={detailNameStyle}>{`${t("description")}: `}</Typography>
            <Typography>
              {probe && probe.description && probe.description[i18n.language]}
            </Typography>
          </Box>
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t("close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

const ShowProbeDetailButton = ({ probe }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("show_detail")}>
        <IconButton size="small" m="0 auto" onClick={() => setOpen(true)}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
      <ProbeDetailDialog open={open} setOpen={setOpen} probe={probe} />
    </>
  );
};

export default ShowProbeDetailButton;
