import { createContext, useState, useContext } from "react";

const AuthContext = createContext();
const SetAuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function useSetAuth() {
  const setAuth = useContext(SetAuthContext);
  return (auth) => {
    setAuth(auth);
    if (auth) {
      localStorage.setItem("auth", JSON.stringify(auth));
    } else {
      localStorage.removeItem("auth");
    }
  };
}

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(() => {
    const auth = localStorage.getItem("auth");
    const accessTokenCookie = document.cookie;
    if (auth && accessTokenCookie) {
      return JSON.parse(auth);
    } else if (auth) {
      localStorage.removeItem("auth");
    }
    return null;
  });

  return (
    <AuthContext.Provider value={auth}>
      <SetAuthContext.Provider value={setAuth}>{children}</SetAuthContext.Provider>
    </AuthContext.Provider>
  );
};

export default AuthContext;
